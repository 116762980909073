import { useNavigate } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import Tag from "./Tag";
import { useTranslation } from "react-i18next";
import Home8Logo from "../../static/Home8Logo.svg";
import SignupButton from "./SignupButton";

interface NoLoginTopBarProps {
    onSignupClick: () => void;
  }

const NoLoginTopBar = ({ onSignupClick }: NoLoginTopBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center w-full p-5">
      <div
        className="cursor-pointer flex items-end gap-1"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Home8Logo} alt="Home8 Logo" className="h-8 w-28" />
      </div>
      <div className="flex items-center gap-2">
        <Tag
          content={t('newPricingPageTabTitle')}
          onClick={() => {
            window.open(`/home/pricing`, "_blank");
          }}
          selected={false}
        />
        <LanguageSelect />
        <SignupButton
          content={t('newSignupButtonLabel')}
          onClick={onSignupClick}
          selected={false}
        />
      </div>
    </div>
  );
};

export default NoLoginTopBar;