import Modal from "react-modal";
import React, { useState } from 'react';
import useAuthStore from '../../home8/store/auth';
import Button, { ButtonType } from './Button';
import Input from './Input';
import { axiosWithoutAuth } from '../../utils/axios';
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useInputCode } from '../../query/useInputCode';
import { useSendSMSCode } from "../../query/useSendSMSCode";

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  setShowPhoneVerificationModal: (open: boolean) => void;
};

const PhoneNumberInputModal = ({
  isModalOpen,
  onModalClose,
  setShowPhoneVerificationModal,
}: Props) => {
  const { t, i18n } = useTranslation();
  const {mutateAsync: inputCode} = useInputCode()
  const {mutateAsync: sendSMSCode} = useSendSMSCode()
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [params, _] = useSearchParams()
  const { phone, setPhone, code, email, loading, setLoading } = useAuthStore();

  const isValidPhoneNumber = (phone: string) => {
    const digits = phone.replace(/\D/g, '');
    return digits.length === 11 && digits.startsWith('1');
  };

  const handleSendCode = async () => {
    setLoading(true);
    try {
      await sendSMSCode({
        phone,
      });
      onModalClose();
      setShowPhoneVerificationModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 480,
          //height: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col items-start justify-start h-full w-full p-10">
        <div className="text-2xl font-bold mb-5">Phone number</div>
        <Input
          className="w-full mb-3"
          placeholder="Your phone number"
          value={phone.startsWith('+1') 
            ? phone.replace(/(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3, p4) => {
                let formatted = `${p1} ${p2}`;
                if (p3) formatted += `-${p3}`;
                if (p4) formatted += `-${p4}`;
                return formatted.trim();
              })
            : phone ? '+1 ' + phone.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
                let formatted = p1;
                if (p2) formatted += `-${p2}`;
                if (p3) formatted += `-${p3}`;
                return formatted;
              })
            : ''}
          required={true}
          isError={false}
          onChange={(e) => {
            let value = (e.target as HTMLInputElement).value;
            // Remove all non-digit characters except +
            value = value.replace(/[^\d+]/g, '');

            // If the value is empty or just '+', reset to empty
            if (value === '' || value === '+') {
              setPhone('');
              return;
            }

            // Ensure it starts with +1
            if (!value.startsWith('+1')) {
              value = '+1 ' + value.replace('+1', '');
            }
            // Limit to proper length (+1 plus 10 digits)
            value = value.slice(0, 12);
            setPhone(value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && isValidPhoneNumber(phone) && !loading) {
              e.preventDefault();
              handleSendCode();
            }
          }}
        />
        <Button
          type={ButtonType.PRIMARY}
          loading={loading}
          disabled={!isValidPhoneNumber(phone)}
          block={true}
          content="Continue"
          onClick={handleSendCode}
        />
        <div className="text-xs text-gray-600 mt-16">
          By proceeding, you consent to get SMS/RCS messages from Home8 to the number provided. Text “STOP” to XXXXX to opt out.
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNumberInputModal;