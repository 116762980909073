import Modal from "react-modal";
import React, { useState } from 'react';
import useAuthStore from '../../home8/store/auth';
import Button, { ButtonType } from './Button';
import Input from './Input';
import { axiosWithoutAuth } from '../../utils/axios';
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useInputCode } from '../../query/useInputCode';
import SendCode from "./SendCode";
import InputCode from "./InputCode";
type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  //setUserAgreementModalOpen: (open: boolean) => void;
};

const EmailInputModal = ({
  isModalOpen,
  onModalClose,
  //setUserAgreementModalOpen,
}: Props) => {
  const { t, i18n } = useTranslation();
  const {mutateAsync: inputCode} = useInputCode()
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [params, _] = useSearchParams()
  const { phone, setPhone, code, email, loading, setLoading } = useAuthStore();
  const [step, setStep] = useState(0);

  const isValidPhoneNumber = (phone: string) => {
    const digits = phone.replace(/\D/g, '');
    return digits.length === 11 && digits.startsWith('1');
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 480,
          //height: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col items-start justify-start h-full w-full p-10">
      {
          step === 0 ? <SendCode onCodeSend={()=>{
            setStep(1)
          }}/> : <InputCode onTryOthers={()=> {
            setStep(0)
          }}/>
      }
      </div>
    </Modal>
  );
};

export default EmailInputModal;