import classNames from "classnames";
import { MoonLoader } from "react-spinners";

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  EXPIRED,
  WARNING,
  DONE,
}

type Props = {
  type: ButtonType;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  content: string;
  subContent?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  block?: boolean;
  contentFontSize?: string;
};

function Button(props: Props) {
  const {
    Icon,
    type,
    content,
    onClick,
    disabled,
    loading,
    subContent,
    block = false,
    contentFontSize,
  } = props;

  const colorMap = {
    [ButtonType.PRIMARY]: {
      text: "white",
      background: "blue-500",
      hover: "blue-600",
      border: "blue-500",
    },
    [ButtonType.SECONDARY]: {
      text: "blue-500",
      background: "blue-50",
      hover: "blue-100",
      border: "blue-50",
    },
    [ButtonType.EXPIRED]: {
      text: "yellow-500",
      background: "yellow-50",
      hover: "yellow-100",
      border: "[#FF7A00]",
    },
    [ButtonType.WARNING]: {
      text: "red-700",
      background: "red-50",
      hover: "red-100",
      border: "[#C40612]",
    },
    [ButtonType.DONE]: {
      text: "green-600",
      background: "green-50",
      hover: "green-100",
      border: "[#13986A]",
    },
  };

  const colorData = disabled
    ? {
        text: "white",
        background: "gray-200",
        hover: "gray-200",
        border: "[#D1D6DC]",
      }
    : colorMap[type];

  if (loading) {
    return (
      <div
        className={classNames(
          `bg-gray-200 rounded-[24px] px-6 py-3 flex gap-1 items-center cursor-not-allowed justify-between`,
          {
            "w-auto": !block,
            "w-full": block,
          }
        )}
      >
        {Icon && <Icon className="fill-white" />}
        <MoonLoader size={16} />
        <div className="text-sm font-semibold text-gray-500 grow">
          {content}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        `bg-${colorData.background} hover:bg-${colorData.hover} h-[48px] min-w-24 rounded-[32px] px-6 py-3 flex gap-2 items-center justify-between`,
        {
          "w-auto": !block,
          "w-full": block,
          "cursor-pointer": !disabled,
          "cursor-not-allowed": disabled,
        }
      )}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}
    >
      {Icon && <Icon className={`fill-${colorData.text}`} />}
      <div className="flex flex-col items-center gap-1 grow">
        <div className={`${contentFontSize || 'text-xs'} font-semibold text-${colorData.text}`}>
          {content}
        </div>
        {Boolean(subContent) && (
          <div className={`${contentFontSize || 'text-xs'} font-light text-${colorData.text}`}>
            {subContent}
          </div>
        )}
      </div>
    </div>
  );
}

export default Button;
