import { ReactNode, useEffect } from "react";
import RequestSentBg from "../../static/RequestSentBg.svg";
import Modal from "react-modal";

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  title: string;
  subTitle?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const NotificationModal = ({
  isModalOpen,
  onModalClose,
  title,
  subTitle,
  Icon,
}: Props) => {
  useEffect(() => {
    if (isModalOpen) {
      setTimeout(onModalClose, 3000);
    }
  }, [isModalOpen]);
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={false}
    >
      <div className="flex flex-col items-center justify-start h-full w-full text-sm">
        {/* {Icon && <Icon />} */}
        <img
          src={RequestSentBg}
          alt="House with couple illustration"
          className="w-auto rounded-[20px]"
        />
        <div className="flex flex-col items-center text-center gap-2 px-5 py-8 w-full">
          <div className="text-xl font-bold">{title}</div>
          {Boolean(subTitle) && (
            <div className="text-sm">{subTitle}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
