import Modal from "react-modal";
import React, { useState } from 'react';
import useAuthStore from '../../home8/store/auth';
import Button, { ButtonType } from './Button';
import Input from './Input';
import { axiosWithoutAuth } from '../../utils/axios';
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useInputCode } from '../../query/useInputCode';
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
import Markdown from 'markdown-to-jsx';
// import termsMarkdown from "../../static/docs/terms.md";

const termsContent = `
# Home8 Terms of Use
These Terms of Use were updated on Dec 1, 2024. Your continued use of the Services constitutes your acceptance of these Terms and the terms of the applicable Services Addendum governing the particular Services provided to you by Home8 Inc. The Services are owned and operated by Home8 Inc, a technology-powered real estate brokerage.


## TERMS OF USE
Welcome! These Terms constitute part of the Agreement regarding the Services between you and Home8 Inc. These Terms provide important information to you, including information about your obligations when using the Services, about content you access through the Services, about content you contribute to the Services, and about the limits of our liability to you. By accessing, downloading, or using any portion of the Services, you signify that you accept the terms of the Agreement.
The Services are, in whole or in part, accessible through the Sites. Your use of and access to each Service is subject to these Terms and the terms of the applicable Services Addendum. Please review each applicable Services Addendum because your use of the corresponding Site and Service constitutes your agreement to the terms of the Services Addendum.

By the date of signing up account, you("Buyer") agree to enter the following Limited Representation Agreement("Agreement") with Home8 ("Agent") CAR LIC #02019805 :

### 1.  Purpose of Agreement
The purpose of this Agreement is to allow the Agent, a CAR member, to assist the Buyer in limited capacities, including providing advice, access to CAR forms, and general guidance during the real estate transaction, without acting as the Buyer's formal representative in the transaction.

### 2. Scope of Services
The Agent agrees to provide the following services:
- Access to CAR forms required for drafting the purchase offer.
- General advice regarding the completion of disclosures and purchase documents.
- Answering questions regarding the real estate transaction process.

The Agent will not:
- Negotiate on behalf of the Buyer.
- Act as a formal Buyer’s Agent in the transaction.
- Be responsible for ensuring the accuracy or completeness of forms/documents submitted by the Buyer.

### 3. Responsibilities of the Buyer
The Buyer agrees to:
- Take full responsibility for the transaction, including submitting forms and negotiating directly with the Seller or Seller’s Agent.
- Acknowledge that the Agent is not acting as a fiduciary representative.
- Hold the Agent harmless from any liability arising from errors, omissions, or disputes in the transaction.

### 4. Compensation
The Buyer and Agent agree that no compensation shall be paid to the Agent for their services under this Agreement unless otherwise mutually agreed in writing.

### 5. Acknowledgment of Limitations
The Buyer understands and acknowledges that this limited representation is not a substitute for full representation by a licensed real estate agent or legal counsel.

### 6. CAR Form Use
The Buyer agrees that all CAR forms provided under this Agreement are copyrighted materials owned by the California Association of Realtors (CAR) and are used solely for the purposes of this transaction.

### 7. Termination of Agreement
This Agreement may be terminated by either party upon written notice to the other party.

### 8. Acknowledgment
By signing this Agreement, both parties confirm their understanding of its terms and agree to proceed accordingly.

**PLEASE NOTE: WHERE PERMITTED BY APPLICABLE LAW, WHEN YOU AGREE TO THIS AGREEMENT AND THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY CLAIM BETWEEN YOU AND HOME8 INC THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 2.13.9 BELOW FOR DETAILS REGARDING ARBITRATION (INCLUDING THE PROCEDURE TO OPT OUT OF ARBITRATION). THIS AGREEMENT ALSO CONTAINS A CLASS ACTION WAIVER.**

**BY ENTERING INTO THIS AGREEMENT, YOU ACKNOWLEDGE THAT YOU UNDERSTAND THIS AGREEMENT AND AGREE TO ALL OF ITS TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE SERVICES.**

### 1. Definitions.
#### 1.1. "AAA" means the American Arbitration Association.
#### 1.2. "AAA Rules" means the Consumer Arbitration Rules of the AAA.
#### 1.3. "Agreement" means, collectively and as applicable to you, these Terms, the applicable Services Addendum, and all other applicable terms, conditions, and policies, including Privacy Notice, that we make available to you in connection with your use of the Services.
#### 1.4. "Claim" means any dispute, controversy or claim arising between you and a Home8 Company.
#### 1.5. "Class Action Waiver" has the meaning set forth in Section 2.13.9.2 of these Terms.
#### 1.6. "Feedback" means feedback, comments, and suggestions for improvements in connection with the Sites and Services.
#### 1.7. "Home8" or "Home8 Inc" or "our" or "we" means, collectively or as applicable to you, the specific entity, and/or the affiliates of such entity, providing the Services as identified in the applicable Services Addendum.
#### 1.8. "Services Addendum" means any applicable addendum incorporated by reference into this Agreement that contains additional terms and provisions concerning the respective Services.
#### 1.9. "Services" means, collectively or as applicable to you, the Sites and services provided by Home8 Inc pursuant to this Agreement and any applicable Services Addendum.
#### 1.10. "Sites" means the websites and mobile applications maintained by or on behalf of Home8 through which the Home8 may offer services.
#### 1.11. "Terms" means these Terms of Use.
#### 1.12. "Testing" has the meaning set forth in Section 2.13.6 of these Terms
#### 1.13. "Third Party Content" means Your Content and any data, content, information, or other materials provided by a third party, including, without limitation (i) such content and information other users of the Services provide to us or upload to the Services, excluding any personally identifiable information, the collection, processing, or any other use of which is governed by the Home8 Private Notice; and (ii) content and information posted or provided by our service providers, or any third party multiple listing service, broker, or agent.
#### 1.14. "Third Party Sites" means third party websites.
#### 1.15. "Your Content" means your Feedback and any data, content, information, ideas, comments, property information, listing information, photos, or other materials that you provide to us or upload to the Services, excluding any personally identifiable information, the collection, processing, or any other use of which is governed by Home8 Private Notice.
All other capitalized terms will have the meaning as provided elsewhere in this Agreement.

### 2. GENERAL TERMS
#### 2.1. Who May Use the Services. To access and use the Services, you must be at least the age of majority in the state, province, or territory where you live or at least 18 years of age. If you are under the age of 13, you may not use the Services and you should not be visiting the Sites or using the Services. For additional user requirements, if any, please see the applicable Services Addendum.
#### 2.2. Third Party Sites and Linked Materials. The Services may include links to Third Party Sites. You should review any applicable terms or Privacy Notice of a Third Party Site before using it or sharing any information with it, because you may give the third party permission to use your information in ways we would not. Home8 is not responsible for and does not endorse any features, content, advertising, products or other materials on or available through Third Party Sites, even if originally accessed via a link on the Sites.


#### 2.3. Intellectual Property Rights, License and Ownership.
#### 2.3.1. Home8 Intellectual Property. Except as otherwise provided in this Agreement, Home8 Inc and its licensors own all right, title, and interest in and to the Services and all materials and information (including source code, data, text, images, and other content) contained on or in the Services, and you acknowledge that all such content is protected by copyright, trademark, and other laws of the United States.
#### 2.3.2. License Granted to You. Home8, as applicable, grants you a limited, personal, non-exclusive, non-transferable, non-sublicensable, revocable license to access, view, and use the Services so long as your use complies with this Agreement, the applicable Services Addendum and any other applicable policies. Unless you are otherwise explicitly granted permission, this Agreement does not grant you any right or license with respect to any trademarks, service marks, graphics, or logos.
#### 2.3.3. Restrictions. You may not modify, decompile, dissemble, reverse-engineer, reproduce, redistribute, create derivative works based upon, or attempt to commercially gain from your use or misuse of the Services, or any of their components, except as permitted by the Services. You may not use any meta-tags or other hidden text using any Home8 name, trademark, service mark, graphic or logo without the respective Home8's specific written permission. You may not remove, obscure, or modify any copyright or other intellectual property notices that appear on or in the Services. You may not use the Services in any way that violates any law or regulation, this Agreement, any of Home8's policies, or any third party policy, that applies to you. You may not use the Services in any manner that harms Home8, our service providers, our suppliers, other users of the Services, or any other person. We may revoke your permission to access and use the Services or terminate your access to and use of the Services, and we may block or prevent you from accessing the Services, in our discretion. If you violate these Terms or the Agreement, your permission to access and use the Services is automatically revoked.
#### 2.3.4. No Right to Reproduce. These Terms do not provide you a license to use, reproduce, distribute, display or provide access to any portion of the Services on Third Party Sites or otherwise.
#### 2.3.5. No Scrubbing or Scraping. You may not automatedly crawl or query the Services for any purpose or by any means (including, without limitation, screen and database scraping, spiders, robots, crawlers and any other automated activity with the purpose of obtaining information from the Services).
#### 2.3.6. Feedback. You may give Home8 Feedback. You hereby assign to the applicable Home8 all of your right, title, and interest in and to the Feedback. To the extent applicable law does not permit assignment of the Feedback, you hereby grant Home8 a perpetual, irrevocable, worldwide, exclusive, transferable, sublicensable, fully paid-up, royalty-free license to use the Feedback in any manner in which the Home8 see fit. You hereby irrevocably waive, to the fullest extent permitted by applicable law, any claims and assertions of moral rights or attribution with respect to your Feedback.
#### 2.3.7. Reservation of Rights. Except for the limited licenses granted in this Agreement, we reserve all of our rights, interests, and title in and to the Services.
#### 2.4. Your Content. You grant to Home8 a worldwide, non-exclusive, transferable, sublicensable, fully paid-up, royalty-free license to use, reproduce, modify (for formatting purposes only), distribute, and perform and display publicly Your Content (excluding Feedback) in connection with provision of the Services to you or otherwise in connection with your permitted use of the Services. In addition to any of Your Content that is made available through the Services, we may share Your Content with our affiliates, service providers and/or subcontractors in order to deliver, develop, or improve the Services. You hereby irrevocably waive, to the fullest extent permitted by applicable law, any claims and assertions of moral rights or attribution with respect to Your Content. Notwithstanding anything in the Agreement to the contrary, no Home8 Company has any obligation to store or display Your Content. Home8 may, but are not obligated to, monitor, modify, or remove Your Content at any time in their sole discretion. You agree that Home8 may promote and market Your Content in connection with the Services.
#### 2.5. Provision of Services and Communications with You. To access or use certain Services or features of the Services, you may need to register and create an account with the applicable Home8 Company. See the applicable Services Addendum for additional terms and conditions in connection with any such registration. For purposes of responding to you and providing you with information and notices about your account or the Services, you agree that we may communicate with you through the contact information associated with your account. We have no liability rising from your failure to maintain accurate contact or other information, including, without limitation, your failure to receive critical information about the Services. When you request information from us, you are extending an express invitation for us to contact you.
#### 2.6. Forums. Home8 may offer forums where you can post your observations and comments on designated topics or on public boards. For certain Services, account holders can create forum topic threads. Home8, in their sole discretion, may close or transfer threads, or modify or remove content from them. Please note that everything you share in a forum may be seen and used by other users of the applicable Services. No Home8 Company is responsible or liable for anything posted by non-Home8 Company persons on the forums nor is any Home8 Company responsible or liable for monitoring the content or activities associated with the forums.
#### 2.7. Your Representations. You represent and warrant that (i) you have the ability to enter into this Agreement and grant all assignments, licenses, and permissions contemplated or contained herein; (ii) your use of the Services will be in compliance with all laws, regulations, this Agreement, any Home8 Company's policies, and third party policies, if and as applicable to you; (iii) Your Content and any Feedback are original to you and do not infringe, misappropriate, or otherwise violate the rights, including any intellectual property rights or rights of publicity or privacy, of any person; (iv) Your Content and any Feedback do not contain any obscene, libelous, defamatory, abusive, or inappropriate content; and (v) our use of Your Content and any Feedback you provide will not infringe, misappropriate, or otherwise violate the rights, including any intellectual property rights or rights of publicity or privacy, of any person.
#### 2.8. Termination. If you have created an account to access the Services, you may deactivate your account at any time by visiting your Account Settings or you may contact the applicable Home8 Company's Customer Service. You hereby acknowledge and agree that we reserve the right at any time to modify or discontinue the Services, in whole or in part, with or without notice, and that we will not be responsible or liable, directly or indirectly, to you or any other person or entity for any loss or damage of any kind incurred as a result of any such modifications or discontinuance.
#### 2.9. Indemnification. You agree to defend, indemnify, and hold each Home8 Company, and its respective agents, employees, directors, officers and representatives harmless from and against all claims and expenses (including, without limitation, reasonable attorneys' fees and costs) arising out of (a) your failure to comply with applicable laws, regulations, this Agreement, any Home8 Company's policies, and third party policies, if and as applicable to you; (b) Your Content and Feedback; (c) Home8' use of Your Content and Feedback as contemplated or permitted under this Agreement; or (d) any activity in which you engage on or in the Services.
#### 2.10. Disclaimers. EACH Home8 COMPANY PROVIDES THE SERVICES ON AN "AS IS" AND "AS AVAILABLE" BASIS. Home8 DO NOT CONTROL OR VET THIRD-PARTY CONTENT FOR ACCURACY. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, Home8 DO NOT PROVIDE ANY EXPRESS OR IMPLIED WARRANTIES, CONDITIONS, OR REPRESENTATIONS REGARDING THE SERVICES AND EACH Home8 COMPANY AND ITS SERVICE PROVIDERS AND LICENSORS DISCLAIM ANY AND ALL IMPLIED WARRANTIES, CONDITIONS, AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, DURABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY OF DATA, AND NONINFRINGEMENT.
Home8 is not responsible for any errors in displayed information or delays in displaying information ON THE SITES, including without limitation any THIRD PARTY CONTENT. HOME8 TAKES NO RESPONSIBILITY OR ASSUMES ANY LIABILITY FOR ANY THIRD PARTY CONTENT. ANY USE OR RELIANCE ON ANY THIRD PARTY CONTENT OR OTHER INFORMATION ON THE SERVICES OR OBTAINED BY YOU THROUGH THE SERVICES IS AT YOUR OWN RISK, INCLUDING INFORMATION PROVIDED BY OR FOR A Home8 COMPANY. HOME8 TAKES NO RESPONSIBILITY FOR THE ACTS OR OMISSIONS OF, OR ANY CONTENT OR INFORMATION PROVIDED BY ANY THIRD PARTY, INCLUDING, WITHOUT LIMITATION, A MULTIPLE LISTING SERVICE OR AN AGENT PARTICIPATING IN THE HOME8 PARTNER PROGRAM.
HOME8 TAKES NO RESPONSIBILITY FOR, AND HOME8 MAKES NO ANY REPRESENTIONS OR WARRANTIES REGARDING, THE DELIVERY OF ANY MESSAGES (SUCH AS POSTING OF ANSWERS OR TRANSMISSION OF ANY CONTENT) SENT THROUGH THE SERVICES. HOME8 HAS NO ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS USING ITS SERVICES, NOR DOES HOME8 HAS ANY OBLIGATION TO MONITOR THE USE OF ITS SERVICES. HOME8 DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION BY OTHERS.
HOME8 MAKES NO GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS AND HOME8 DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS.
THE INFORMATION ON THE SERVICES IS NOT AN OFFER OR SOLICITATION BY ANYONE IN ANY JURISDICTION IN WHICH AN OFFER OR SOLICITATION CANNOT LEGALLY BE MADE, OR TO ANY PERSON TO WHOM IT IS UNLAWFUL TO MAKE A SOLICITATION.
#### 2.11. Limitations of Liability. IN NO EVENT WILL HOME8 OR ANY OF ITS AGENTS, EMPLOYEES, DIRECTORS, OFFICERS OR REPRESENTATIVES BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM THESE TERMS, THE AGREEMENT, OR YOUR USE OF THE SERVICES, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (A) BREACH OF CONTRACT, (B) BREACH OF WARRANTY, (C) NEGLIGENCE, OR (D) ANY OTHER CAUSE OF ACTION, TO THE EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW. IF YOU DO NOT AGREE WITH ANY PART OF THIS AGREEMENT, OR YOU HAVE ANY DISPUTE OR CLAIM AGAINST HOME8, ITS AGENTS, EMPLOYEES, DIRECTORS, OFFICERS OR REPRESENTATIVES WITH RESPECT TO THIS AGREEMENT OR THE SERVICES, THEN EXCEPT AS SPECIFICALLY PROVIDED IN THIS AGREEMENT OR WHERE THE LAW REQUIRES A DIFFERENT STANDARD, YOUR SOLE AND EXCLUSIVE REMEDY IS TO TERMINATE YOUR ACCOUNT FOR SUCH SERVICES, IF APPLICABLE, AND/OR DISCONTINUE USING THE SERVICES.
#### 2.12. Copyright and Intellectual Property Policy. Home8 respect the intellectual property rights of others and expect our users to do the same. It is Home8' policy to terminate, in appropriate circumstances, account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders. If you believe your copyright or other intellectual property right is being infringed, misappropriated, or otherwise violated by a user of the Services, or you believe such a claim has been unfairly made against your account, please see Home8' Copyright & Intellectual Property Policy.
#### 2.13. General Terms.
#### 2.13.1. Severability. If any provision of this Agreement is found by a court of competent jurisdiction or arbitrator to be illegal, void, or unenforceable, the unenforceable provision will be modified so as to render it enforceable and effective to the maximum extent possible in order to effect the intention of the provision.
#### 2.13.2. Notices. Home8 Corporation is located at 1381 Socorro Avenue, Sunnyvale, CA 94089. You may contact Home8 by sending correspondence to that address or emailing support@home8.ai
#### 2.13.3. Amendments to The Agreement. We may update the Agreement, including these Terms, at any time, in our sole discretion. If we do so, we will let you know either by posting the updated Agreement on the Sites or through other communications. It is important that you review the Agreement, including these Terms, whenever we update it or you use the Services. If you continue to use the Services after we have posted an updated Agreement, you are agreeing to be bound by the updated Agreement. If you do not agree to be bound by the updated Agreement, then, except as otherwise provided in Section 2.13.9, you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.
#### 2.13.4. No Waiver. Our failure to act with respect to a breach of this Agreement or any term or condition herein by you or others does not waive our right to act with respect to that breach or subsequent similar or other breaches.
#### 2.13.5. Assignment and Delegation. You may not assign or delegate any rights or obligations under this Agreement. Any purported assignment or delegation by you shall be ineffective. We may freely assign or delegate all rights and obligations under this Agreement, fully or partially, without notice to you. We may also substitute, by way of unilateral novation, effective upon notice to you, a Company for any third party that assumes our rights and obligations under this Agreement.
#### 2.13.6. Alpha and Beta Testing. The Services include any alpha or beta testing or other evaluation or use of products and services, features, functionality, and all components thereof (whether in final or pre-release form) that we may conduct ("Testing"). Except to the extent otherwise provided in another agreement between you and the applicable Home8 Company, your participation in any Testing and use of any data, content, information, or other materials in connection with such Testing shall be subject to this Agreement.
#### 2.13.7. International Matters. Unless otherwise stated in the applicable Services Addendum, Home8 is controlled and operated from United States offices. We make no representation that the Services are appropriate or available for use in any particular country or location. If you choose to access, view, or use the Services, you do so on your own initiative and you are responsible for compliance with local laws, if and to the extent local laws are applicable. No Services, including software, may be downloaded or otherwise exported or re-exported in violation of any applicable law, rule, regulation, or export or import control.
#### 2.13.8. Governing Law.Except as otherwise provided in Section 2.13.9, you hereby accept and submit to the personal jurisdiction of the applicable courts in accordance with this Section 2.13.8 with respect to any legal actions, suits or proceedings, and waive any jurisdictional or venue defenses otherwise available. The International Sale of Goods Act (British Columbia) and the United Nations Convention on Contracts for the International Sale of Goods, will not apply in any way to this Agreement or to the transactions contemplated by this Agreement.
#### 2.13.8.1. For Users Who Are Residents of the United States.This Agreement, any related matters, and any Claims arising under or related thereto (whether for breach of contract, tortious conduct or otherwise) will be governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of Washington, without reference to its conflicts of law principles.

#### 2.13.9. Dispute Resolution. THIS SECTION 2.13.9 APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AND IS VOID WHERE PROHIBITED BY LAW. This arbitration provision shall survive termination of the Agreement.
#### 2.13.9.1. Informal Negotiations. To expedite resolution and reduce the cost of a Claim, you and the applicable Home8 Inc agree to first attempt to negotiate any Claim (except those Claims expressly excluded below) informally for at least sixty (60) days before initiating any arbitration or court proceeding. Such informal negotiations will commence upon written notice.
The Notice of Dispute must contain enough information for us to identify all relationship(s) that you have with Home8 and attempt to resolve your claim, including (a) your name; (b) the email address(es) that you use when communicating with or using the Home8 services; (c) your mobile telephone number; (d) your state of residence; (e) a written description of the issues, and supporting information; and (f) a good faith calculation of the damages you claim to have suffered and a statement of the specific relief you are seeking. You may be represented by an attorney or other person in that process.
Your address for such notices is your personal home address, if provided by you, with an email copy to the email address you may have provided to Home8 Inc.
You and Home8 each agree to negotiate your claim in good faith. You agree that you may not commence any arbitration or court proceeding unless you and we are unable to resolve the claim within 60 days after we receive your claim description and you have made a good faith effort to resolve your claim directly with us during that time. If we are unable to resolve your claim within 60 days despite those good faith efforts, then either you or Home8 may start an arbitration or small claims court proceeding.
#### 2.13.9.2. Binding Arbitration and Class Action Waiver. Subject to Section 2.13.9.3, you and Home8 agree that any Claim between you and Home8 Inc will be resolved solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding. This Section 2.13.9 is governed, in all respects, both procedurally and substantively, by the United States Federal Arbitration Act. You and Home8 Inc agree that you are each waiving the right to a trial by jury or to participate in a class action.
YOU AND HOME8 INC AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties' Claim is resolved through arbitration, the arbitrator may not consolidate another person's claims with your claims, and may not otherwise preside over any form of a representative or class proceeding (the two foregoing sentences shall constitute the "Class Action Waiver". If this Class Action Waiver is found to be unenforceable, then the entirety of this Section 2.13.9 shall be null and void.
SUBJECT TO THE ABOVE THE PARTIES HEREBY WAIVE ANY RIGHTS THEY MAY HAVE TO TRIAL BY JURY IN REGARD TO CLAIMS COVERED BY THIS AGREEMENT.
If any other provision of this Section 2.13.9 requiring that Claims be brought only on an individual basis and not on a class, collective, or representative basis, is determined to be invalid or unenforceable with respect to any particular Claim, including any remedy or request for relief, then that particular Claim, remedy or request for relief shall not proceed in arbitration but rather will be resolved in a court of competent jurisdiction subject to Section 2.13.8. If that happens, however, the arbitration provisions in this Section 2.13.9 will still be fully enforceable as to all other Claims, which must be resolved in arbitration on an individual basis. Any arbitrable Claims will be resolved before non-arbitrable Claims, which the parties will jointly request to be stayed pending the conclusion of arbitration. In any subsequent litigation of non-arbitrable claims, the arbitrator’s factual findings will not be entitled to deference by the court.
#### 2.13.9.3. Exceptions and Opt-Out. Nothing in this Section 2.13.9 precludes any party from filing or participating in administrative proceedings before state, provincial/territorial, or federal agencies to address alleged violations of law enforced by those agencies. Further, to the extent a party would have to file a timely administrative charge or complaint as a prerequisite to filing a Claim in court, the party must do the same before submitting a Claim to arbitration under this Agreement. Upon receipt of a right-to-sue letter or similar administrative determination, however, the Claim can only be resolved in individual arbitration pursuant to the terms of this Agreement.
This Section 2.13.9 also does not prevent any party from applying to a court of competent jurisdiction for any interim or provisional relief available under the law that is necessary to protect the rights of that party, pending the establishment of the arbitral tribunal. This Section 2.13.9 further does not prevent any party from filing any Claim that otherwise qualifies in small claims court on an individual basis.
In addition, you have the right to opt out of arbitration entirely and litigate any Claim if you provide Home8 with whom you are entering into this Agreement with personally signed, written notice of your desire to do so by email at support@home8.ai within thirty (30) days following the date you first agree to this Agreement.
#### 2.13.9.4. Equitable Remedies. Except for individual Claims brought in small claims court, the arbitrator shall determine all issues of liability on the merits of any Claim asserted by you or Home8, and may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual Claim. To the extent that you or Home8 prevail on a Claim in arbitration and seek injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the general public, the entitlement and extent of such relief must be litigated in a civil court of competent jurisdiction, subject to Section 2.13.8 and not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual Claims in arbitration.
#### 2.13.9.5. Rules and Logistics Governing Arbitration. The arbitration will be conducted by the AAA under its AAA Rules then in effect, except as modified by these Terms. The AAA Rules are available at www.adr.org or by calling 1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at www.adr.org.
If your claim is for U.S. $10,000 or less, you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic or video-conference hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds U.S. $10,000, the right to a hearing will be determined by the AAA Rules. Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement.
Any evidence introduced or produced in connection with the arbitral proceeding may only be used in that AAA proceeding, and may not be disclosed or used for any other purpose, including any other arbitration of similar claims.
#### 2.13.9.6. Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules. If you initiate an arbitration, you are required to pay AAA’s initial filing fee, but the applicable Home8 Company will reimburse you for this filing fee at the conclusion of the arbitration if that filing exceeds the fee for filing a complaint in a federal or state court in your county of residence. If the arbitrator finds that your claim was brought for an improper purpose (as per Federal Rule of Civil Procedure 11(b) standards) or that your claim or the relief sought by it was frivolous, then will not reimburse your filing fee and we may seek an award of our legal fees or costs against you and/or your counsel. This Agreement authorizes the arbitrator to award fees or other sanctions against your counsel.
#### 2.13.9.7. Delivery of Demand for Arbitration. A demand for arbitration must be in writing and delivered by hand or first-class mail to the other party within the applicable statute of limitations period. Any demand for arbitration shall be provided to the applicable Home8 Company pursuant to Section 2.13.2. Any demand for arbitration made by a Home8 Company shall be provided to the last address on file with Home8. The arbitrator shall resolve all disputes regarding the timeliness or propriety of the demand for arbitration.
#### 2.13.9.8. Effect of Changes on Arbitration. Notwithstanding Section 2.13.3 above, if we make any future changes to this Section 2.13.9 (other than a change to our contact information), you may reject any such change by sending an email at support@home8.ai within thirty (30) days of the change. This written notice does not constitute an opt out of arbitration altogether. By rejecting any future change, you are agreeing that you will arbitrate any Claim between you and Home8 in accordance with the latest in time version of Section 2.13.9 that you did not effectively reject.
#### 2.13.10. No Injunctive Relief. In no event shall you seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation of the Services.
#### 2.13.11. Entire Agreement. You agree that this Agreement constitutes the entire, complete, and exclusive agreement between you and Home8 Inc regarding the Services and supersedes all prior agreements and understandings, whether written or oral, or whether established by custom, practice, policy or precedent, with respect to the subject matter of this Agreement. You also may be subject to additional terms and conditions that may apply when you use or receive certain Services, which are reflected in the applicable Services Addendum or otherwise communicated to you by the applicable Home8 Company. Without limiting the foregoing, if you enter into a Home8 Agent Agreement, that Home8 Agent Agreement may incorporate the terms of this Agreement by reference. To the extent the terms of a Home8 Agent Agreement conflict with this Agreement, the terms of the Home8 Agent Agreement shall control, with the exception of Section 2.13.9 of this Agreement, which shall control in all instances.

ADDENDUM: Home8 SERVICES
Welcome! This Home8 Services Addendum ("Home8 Services Addendum") is hereby incorporated into and is a part of the overall Agreement between you and Home8. The terms of this Home8 Services Addendum supplement the terms of the Agreement with respect to the Home8 Services (defined below) and in the event any term of this Home8 Services Addendum directly conflicts with a term of the Agreement, the term contained in this Home8 Services Addendum will control.
The Home8 Services are offered by Home8 and by accessing, downloading, or using any portion of the Home8 Services, you signify that you accept the terms of this Home8 Services Addendum as between you and Home8.
### 3. DEFINITIONS
#### 1.1. "Agent" means a licensed real estate agent in the jurisdiction in which the agent is performing services.
#### 1.2. "MLS®" means multiple listing service.
#### 1.3. "Home8" means, if you are located in the United States, Home8 Corporation, or, if you are located in Canada, Home8 Unlimited Liability Company.
#### 1.4. "Home8 Agent" means an Agent employed or otherwise acting directly on behalf of Home8 pursuant to a written agreement between Home8 and the Agent. For clarification, Home8 Agents do not include Agents participating in the Home8 Partner Program.
#### 1.5. "Home8 Client" means a person who has entered into an agreement with a Home8 Agent for the purpose of listing, selling, or buying a property in connection with the Home8 Services.
#### 1.6. "Home8 Partner Program" means the partner program offered by Home8 for Agents who are not Home8 Agents.
#### 1.7. "Home8 Services" means the Home8 Sites and the services provided by or for Home8 through the Home8 Sites that are not otherwise covered by the Home8 Agent Agreements.
#### 1.8. "Home8 Sites" means Home8.com, Home8.ca, and other websites and mobile applications through which Home8 may offer services.
All other capitalized terms will have the meaning as provided elsewhere in this Home8 Services Addendum or in the Agreement.
### 4. Home8 SERVICES TERMS
#### 2.1. You are required to enter into a separate listing or buyer agreement to become a Home8 Client and list your home or buy a home using the services of a Home8 Agent (the “Home8 Agent Agreements”). The Home8 Agent Agreements may incorporate the terms of this Agreement by reference.
#### 2.2. Who May Use the Home8 Services. To use the Home8 Services, you must be a resident of the United States or Canada.
#### 2.3. Permissible Use of the Home8 Services. Except as otherwise permitted under the Home8 Partner Program, you acknowledge and agree that you are a homeowner or that you are an Agent acting directly on behalf of a homeowner. You agree to use the Home8 Services only for residential real estate transactions and not for commercial real estate transactions of any kind. You agree that any listing information you post is about your or your client's home. You will not assert ownership rights of any kind in any listing information not provided by you.
#### 2.4. Provision of Services. To access certain features and tools offered by Home8 or its service providers in connection with the Home8 Services, you may need to register with Home8 or its service providers. You agree to provide accurate, true, current, and complete information to Home8. The personal information you provide in connection with the Home8 Services will be protected as described in our Privacy Notice. You are responsible for all activities related to the Home8 Services that occur through your account and password. You agree to keep your password confidential, not use others' accounts, nor permit others to use your account. We reserve the right to terminate accounts in our sole discretion.
#### 2.5. Submitting Content to Home8 Services.
#### 2.5.1. Permissible Submissions. You may submit Your Content (including those reviews described in Section 2.5.8 below) in accordance with this Agreement, including, without limitation, the Listing Submission Guidelines (see Section 2.5.3) and the Community Guidelines (see Section 2.5.4) in this Home8 Services Addendum.
#### 2.5.2. Representations. You represent and warrant that (i) Your Content is original to you and does not infringe, misappropriate, or otherwise violate the rights, including any intellectual property rights or rights of publicity or privacy, of any person; (ii) Your Content does not contain any obscene, libelous, defamatory, abusive, or inappropriate content; and (iii) Home8's use of Your Content you provide will not infringe, misappropriate, or otherwise violate the rights, including any intellectual property rights or rights of publicity or privacy, of any person. You are solely responsible for Your Content.
#### 2.5.3. Listing Submission Guidelines.
#### 2.5.3.1. Listings may only be posted to, or updated on, the Home8 Services by the owner of the listed property (e.g., a For Sale by Owner listing) or by the contracted, exclusive listing Agent for such listing. Home8 may require that you provide proof you are the owner of the subject property, or that you have an exclusive right to post or advertise the listing (e.g., by requiring a copy of the listing agreement). If Home8 is unable to verify that you are the homeowner or are the contracted, exclusive listing Agent, Home8 may remove the listing at any time in its sole discretion.
#### 2.5.3.2. If you are an Agent providing a listing to or through the Home8 Services, you hereby acknowledge and agree that:
(a) the owner of the home that is the subject of your listing has provided his/her consent and authorized you to post the listing to the Home8 Services and doing so does not violate any term of your agreement with, or legal duties owed to, the owner of the home;
(b) your broker has authorized you to post the listing to the Home8 Services and doing so does not violate any term of your agreement with, or legal duties owed to, your broker;
(c) you have complied with all relevant laws, regulations, and policies, including those overseen or enforced by the applicable real estate licensing regulatory authorities, the applicable MLS® and any applicable realtor's association, including as applicable the ASSOCIATION OF REALTORS©; and
(d) you are only submitting information that you know to be true and accurate, and you will update or remove your listing submitted to the Home8 Services immediately if you become aware that information included therein is or becomes untrue or inaccurate.
#### 2.5.4. Community Guidelines. All of Your Content, including listing submissions, must comply with this Agreement, including these Community Guidelines, all as may be interpreted by Home8 in its sole discretion. You hereby acknowledge and agree to comply with the following Community Guidelines:
#### 2.5.4.1. Be respectful. We welcome debate, but we will not tolerate personal attacks or defamatory statements about other users, clients, customers or agents. Please avoid controversial topics such as politics, race, religion, and sexuality.
#### 2.5.4.2. Be honest. Only post information that you know is true and accurate. Similarly, do not misrepresent your identity or your affiliation with another person or entity. If you submit a review, your review must reflect your true and honest opinion of and experience with the provider, product, or service you review, and you must disclose any incentive you received for providing your review (e.g., if you received any discount for providing the review or you are connected (e.g., through employment or familial relationship) to the subject of the review).
#### 2.5.4.3. Submit only what you have a right to submit. Do not submit or post any content that violates any applicable law or that infringes copyright or other intellectual property rights, including in the photos and written content that you submit. Do not submit information about real estate properties you do not own, unless you are authorized to do so as the contracted, exclusive listing Agent for that property.
#### 2.5.4.4. Respect privacy and confidentiality. Do not publicly post the personal or private information of others or other peoples' contact information. This also includes communications from community administrators and moderators. In general, do not publicly disclose any confidential or sensitive information.
#### 2.5.4.5. No solicitation. Real estate agents, brokers, salespersons, and other professionals are welcome on the Home8 Sites, but not to promote their services. You can include information about your brokerage business in Your Content, but you cannot include your brokerage business's general contact information or website addresses. Do not embed any marketing information in listing photos or videos, including an agent's name, company or brokerage name, logo, phone number, or email address. (Where available, Home8 will display the name, photo, license number, brokerage name, phone number, and/or email address of listing Agents who submit listings to the Services).
#### 2.5.4.6. No spamming. Do not post advertising, junk mail, spam, scams, or chain letters.
#### 2.5.4.7. No illegal or offensive posts. Do not submit or post anything that contains abusive, threatening, illegal, inflammatory, libelous, obscene, or pornographic content.
#### 2.5.4.8. No discrimination. Do not post any content that expresses a discriminatory preference, even implicitly.
#### 2.5.4.9. Individuals. Do not post any information that identifies any individual person whether such person is a property-owner or otherwise, or any information that could be used to identify any individual person, except for any Agent, salesperson, or broker associate with the applicable property.
#### 2.5.5. MLS®s Not Responsible for Submissions. The MLS®s are not responsible or liable, directly or indirectly, in any way, for any loss or damage of any kind incurred as a result of, or in connection with, your or any other user's use of or reliance on any listing information, any related data, or posted or hyperlinked comments or reviews on the Home8 Sites.
#### 2.5.6. MLS®s Do Not Endorse Submissions. The MLS®s do not endorse any posted or hyperlinked comments or reviews on the Home8 Sites.
#### 2.5.7. Home8's License to Use Your Content. You hereby grant to Home8 a license in Your Content pursuant to the Terms of Use of this Agreement.
#### 2.5.8. None exclusive representative agreement. You hereby agree that Home8 represents you to request disclosures and further information regarding the listing. 
#### 2.6. Partner Agent Program. Home8 is not responsible for the work performed or the services provided by Agents in connection with the Partner Agent Program.
#### 2.7. Equal Housing Opportunity. Home8 is pledged to the letter and spirit of United States and Canadian policy for the achievement of equal housing opportunity throughout the areas in which we offer Home8 Services and to compliance with all applicable human rights legislation. We encourage and support an affirmative advertising and marketing program in which there are no barriers to obtaining housing because of race, color, religion, sex, sexual orientation, gender identity or expression, handicap, familial status, national origin, or other legally protected group.
#### 2.8. Home8 Services Content.
#### 2.8.1. Neighborhood Location Outlines. Any neighborhood outlines that appear on the Home8 Sites or Home8 Services have been supplied by Maponics LLC.
#### 2.8.2. School Ratings and Reviews. Where available, GreatSchools ratings are provided by GreatSchools.net.
#### 2.9. MLS® Terms of Use. Before we can show you pictures and prices of sold homes, or show you comments on active listings from Home8 Agents, our MLS data providers require you to acknowledge that you consider Home8 to be your real estate agent, broker or salesperson. For purposes of clarification, talking to a Home8 Agent generally about listings or home sales comps does not create an obligation to work with a Home8 Agent to buy or sell a home. However, you are required to enter into a Home8 Agent Agreement and become a Home8 Client before you can list your home or buy a home using the services of a Home8 Agent. Before proceeding to view the content made available through the Home8 Services, you must acknowledge all of the following:
#### 2.9.1. You are entering into a lawful consumer-broker, seller/buyer-brokerage, or similar relationship with Home8 (as defined by applicable state or provincial/territorial law). You have no obligation to work with Home8 and you can terminate your account with Home8 at any time. Any information you obtain from the Home8 website is intended for your personal, non-commercial use.
#### 2.9.2. You have a bona fide interest in the purchase, sale, or lease of real estate on the Home8 Sites.
#### 2.9.3. You will not copy, redistribute, or retransmit any of the information provided except in connection with your consideration of the purchase or sale of an individual property.
#### 2.9.4. You will not, directly or indirectly, display, post, disseminate, distribute, publish, broadcast, transfer, sell, or sublicense, any information provided through the Services to another individual or entity. This prohibition expressly includes "scraping" (including screen and database scraping), "data mining", or any other activity intended to collect, store, re-organize, summarize, or manipulate any information provided or any related data.
#### 2.9.5. You acknowledge that the individual MLS® that supplies the listing data is the owner of such data and you acknowledge the validity of the MLS®'s system, and MLS®'s proprietary rights and copyright to such data and any related data.
#### 2.9.6. Home8 also explicitly authorizes MLS® employees, MLS® members, or their duly authorized representatives to access Home8's website for the purposes of verifying compliance with MLS® rules and monitoring the display of participants' listings on Home8's site.
#### 2.9.7. Home8 makes no guarantees as to the reliability, accuracy, or up to date nature of any information from MLS® sources.
#### 2.9.8. Additional disclosures apply to residents of Colorado, Kansas, Missouri, New York, North Carolina, Rhode Island, South Carolina, and Texas.
#### 2.10. Location-Specific Agreements and Disclaimers for MLS®s and other Data Providers. Home8 receives the home listing information from many listing sources. The following disclaimers apply to listings only from that particular data source. To the extent there are defined terms in the disclaimers below, such definitions shall only apply to the use of those terms in such particular disclaimer.

## MLSListings Inc. (MLSListings)
Property Information © 2024 MLSListings Inc. All rights reserved. Listing information is deemed reliable but not guaranteed. Certain information contained herein is derived from information which is the licensed property of, and copyrighted by, MLSListings Inc. The viewer acknowledges ownership and validity of its copyright. Information provided is for personal, non-commercial use by a viewer with a bona fide interest in the purchase or sale of real estate of the type being offered via this website or other electronic means. The viewer is prohibited from copying, redistributing or retransmitting any of the data or information provided by MLSListing, Inc.


`;

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
};

const UserAgreementModal = ({
  isModalOpen,
  onModalClose,
}: Props) => {
  const { t, i18n } = useTranslation();
  const {mutateAsync: inputCode} = useInputCode()
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [params, _] = useSearchParams()
  const { phone, code, email, smsCode, loading, setLoading } = useAuthStore();
  const [canScroll, setCanScroll] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  //const [markdownContent, setMarkdownContent] = React.useState("");

  // React.useEffect(() => {
  //   // Use a relative path from the current location
  //   fetch("../../static/docs/terms.md", {
  //     headers: {
  //       'Content-Type': 'text/plain',
  //       'Accept': 'text/plain'
  //     }
  //   })
  //     .then((response) => response.text())
  //     .then((text) => setMarkdownContent(text))
  //     .catch((error) => console.error("Error loading terms:", error));
  // }, []);

  const checkIfScrollable = (element: HTMLDivElement) => {
    setCanScroll(element.scrollHeight > element.clientHeight);
    // If there's no need to scroll, we should consider it as "scrolled to bottom"
    if (element.scrollHeight <= element.clientHeight) {
      setHasScrolledToBottom(true);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const isAtBottom = Math.abs(
      element.scrollHeight - element.scrollTop - element.clientHeight
    ) < 1;
    
    if (isAtBottom) {
      setHasScrolledToBottom(true);
    }
  };

  console.log(termsContent)

  const markdownOverrides = {
    overrides: {
      h1: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLHeadingElement>) => (
          <h1 {...props} className="text-2xl font-bold mb-4 mt-6">{children}</h1>
        )
      },
      h2: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLHeadingElement>) => (
          <h2 {...props} className="text-xl font-bold mb-3 mt-5">{children}</h2>
        )
      },
      h3: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLHeadingElement>) => (
          <h3 {...props} className="text-lg font-bold mb-2 mt-4">{children}</h3>
        )
      },
      h4: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLHeadingElement>) => (
          <h4 {...props} className="text-sm text-gray-600 mb-2 mt-3">{children}</h4>
        )
      },
      p: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLParagraphElement>) => (
          <p {...props} className="mb-4 text-sm text-gray-600 leading-relaxed">{children}</p>
        )
      },
      ul: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLUListElement>) => (
          <ul {...props} className="list-disc pl-6 mb-4">{children}</ul>
        )
      },
      li: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLLIElement>) => (
          <li {...props} className="mb-2 text-sm text-gray-600">{children}</li>
        )
      },
      strong: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLElement>) => (
          <strong {...props} className="font-bold">{children}</strong>
        )
      },
      a: {
        component: ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLElement>) => (
          <a {...props} className="text-blue-500 hover:text-blue-600 text-sm no-underline">{children}</a>
        )
      }
    },
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          //height: 548,
          maxHeight: '90vh',
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          //overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col h-full w-full p-5">
        <div className="text-sm font-bold mb-2">Agreement</div>
        <div className="flex-1 overflow-y-auto text-sm text-gray-600 max-h-[calc(90vh-160px)]" onScroll={handleScroll} ref={(el) => el && checkIfScrollable(el)}>
          {/* <div className="text-sm text-gray-600 mb-5">
          As a brokerage, Home8 has access to the databases that all brokerages use to list properties. 
          That means we can show you all the properties listed by other brokerages, unless the seller has requested 
          that the listing not be published or marketed online. Home8 updates this data as quickly as possible and shares 
          as much information with our users as allowed by local rules.
          </div>
          <div className="text-sm text-gray-600 mb-5">
          The property reports provided by Home8 are generated using AI and are based on the disclosures 
          provided by the seller. While we strive for accuracy, there may be errors or omissions in the 
          reports. Always refer to the original disclosures for the most accurate information.
          </div>
          <div className="text-sm text-gray-600 mb-5">
          Please note that listing agents may update disclosures multiple times. Although we make every 
          effort to keep our records up to date, some discrepancies may occur. To ensure you have the 
          latest disclosure package before submitting an offer, kindly verify with the listing agent or contact our support team.
          </div>
          <div className="text-sm text-gray-600">
          On each property report page on Home8, we show a Mismatch section that would compare listing 
          information and public information about that property, such as bedroom count, bathroom count, 
          the home's square footage, and permit information that is available from public records. Home8 
          receives this information from a third party who gets it directly from the local county assessor 
          and recorder offices, this information can occasionally be incomplete or outdated, Home8 
          can not guarantee the accuracy of the information, users will need to do their own investigation.
          </div> */}

        {/* <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown> */}
        <Markdown options={markdownOverrides}>
          {termsContent}
        </Markdown>

        </div>
        <div className="border-t-0 mt-5 flex justify-end">
          <Button
            type={ButtonType.PRIMARY}
            loading={loading}
            //disabled={canScroll &&!hasScrolledToBottom}
            disabled={false}
            content="Agree and create account"
            onClick={async () => {
              setLoading(true);
              try {
                await inputCode({
                  email: email,
                  code: code,
                  phone: phone,
                  phone_code: smsCode
                });
                onModalClose();
              } finally {
                setLoading(false);
                onModalClose();
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserAgreementModal;