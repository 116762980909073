import React, { useState } from 'react';
import { useSendCode } from '../query/useSendCode';
import Button, { ButtonType } from './Button';
import Input from './Input';
import useAuthStore from './store/auth';
import PinInput from 'react-pin-input';
import { NONAME } from 'dns';
import { useInputCode } from '../query/useInputCode';
import PhoneNumberInputModal from '../home8_new/components/PhoneNumberInputModal';
import UserAgreementModal from '../home8_new/components/UserAgreementModal';
import PhoneVerificationModal from '../home8_new/components/PhoneVerificationModal';

type Props = {
  onTryOthers: () => void;
};

function InputCode({ onTryOthers }: Props) {
  const { mutateAsync: sendCode } = useSendCode();
  const {mutateAsync: inputCode} = useInputCode()
  const { code, setCode, loading, setLoading, email } = useAuthStore()
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false); // Add this state
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false);
  const [userAgreementModalOpen, setUserAgreementModalOpen] = useState(false);
  console.log(code)
  return (
    <>
    <div className='flex flex-col h-full justify-between gap-5'>
      <div className='flex flex-col gap-5'>
        <div className="text-2xl font-bold">Enter email verification code</div>
        <div>
          <span>{`An email with a 6-digit verification code was just sent to ${email} `}</span>
          <a onClick={() => sendCode({
            email,
          })}>Resend Code</a>
        </div>
        <PinInput
          length={6}
          initialValue={code}
          onComplete={(value, index) => {
              setCode(value)
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ border: "none", marginLeft: 8, marginRight: 8, borderRadius: 12, backgroundColor: '#EEF0F2' }}
          inputFocusStyle={{ borderWidth: 2, borderStyle: 'solid', borderColor: '#126FD6' }}
          autoSelect={true}
          regexCriteria={/^[0-9]*$/}
        />
      </div>
      <div className='flex items-center gap-4'>
        <Button
          type={ButtonType.SECONDARY}
          loading={loading}
          disabled={false}
          content="Try another way"
          onClick={async () => {
            onTryOthers()
          }}
        />
        <Button
          type={ButtonType.PRIMARY}
          loading={loading}
          disabled={code.length !== 6}
          content="Log in"
          onClick={async () => {
            setLoading(true);
            try {
              const result = await inputCode({
                email: email,
                code: code,
              });
              console.log(result)
              if (result?.needPhoneNumber) {
                setShowPhoneNumberModal(true);
              }
            } finally {
              setLoading(false);
            }
          }}
        />
      </div>
    </div>

    {/* Phone Number Modal */}
    {showPhoneNumberModal && (
        <PhoneNumberInputModal
          isModalOpen={showPhoneNumberModal}
          onModalClose={() => setShowPhoneNumberModal(false)}
          setShowPhoneVerificationModal={setShowPhoneVerificationModal}
        />
    ) }
    {/* Phone Verification Modal */}
    {showPhoneVerificationModal && (
        <PhoneVerificationModal
          isModalOpen={showPhoneVerificationModal}
          onModalClose={() => setShowPhoneVerificationModal(false)}
          setUserAgreementModalOpen={setUserAgreementModalOpen}
          onTryOthers={() => {
            setShowPhoneVerificationModal(false);
            setShowPhoneNumberModal(true);
          }}
        />
    ) }
    {userAgreementModalOpen && (
        <UserAgreementModal
          isModalOpen={userAgreementModalOpen}
          onModalClose={() => setUserAgreementModalOpen(false)}
        />
    ) }
    </>
  );
}

export default InputCode;
