import { useMutation } from '@tanstack/react-query';
import qs from 'qs';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/auth';
import { axiosWithAuth, axiosWithoutAuth } from '../utils/axios';

interface SendSMSCodeRequest {
  phone: string;
}

export const useSendSMSCode = () => {
  const axios = axiosWithoutAuth();

  const sendSMSCode = async ({ phone }: SendSMSCodeRequest) => {
    try {
      await axios.post(
        'auth/send_sms_code',
        {
          phone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

    } catch {
    } 
  };
  const { mutateAsync } = useMutation(sendSMSCode);
  return { mutateAsync };
};
