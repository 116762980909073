import Modal from "react-modal";
import React, { useState } from 'react';
import useAuthStore from '../../home8/store/auth';
import Button, { ButtonType } from './Button';
import Input from './Input';
import { axiosWithoutAuth } from '../../utils/axios';
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useInputCode } from '../../query/useInputCode';
import { useSendSMSCode } from '../../query/useSendSMSCode';
import PinInput from 'react-pin-input';


type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  setUserAgreementModalOpen: (open: boolean) => void;
  onTryOthers: () => void;
};

const PhoneVerificationModal = ({
  isModalOpen,
  onModalClose,
  setUserAgreementModalOpen,
  onTryOthers,
}: Props) => {
  const { t, i18n } = useTranslation();
  const {mutateAsync: sendSMSCode} = useSendSMSCode()
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [params, _] = useSearchParams()
  const { phone, setPhone, code, smsCode, setSMSCode, loading, setLoading } = useAuthStore();
  const [smsPinKey, setSmsPinKey] = useState(0); 

  const isValidPhoneNumber = (phone: string) => {
    const digits = phone.replace(/\D/g, '');
    return digits.length === 11 && digits.startsWith('1');
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 480,
          //height: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
    <div className='flex flex-col h-full justify-between p-10' onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setUserAgreementModalOpen(true);
        }
      }}>
        <div className='flex flex-col'>
          <div className="text-2xl font-bold mb-4">Enter SMS verification code</div>
          <div className='text-sm mb-5'>
            Enter the code we sent over SMS to <span className="font-bold">{phone}</span>. 
            <a onClick={() => sendSMSCode({
              phone,
            })}> Resend Code</a>
          </div>
          <PinInput
            key={smsPinKey}
            length={6}
            initialValue={smsCode}
            onComplete={(value, index) => {
                setSMSCode(value);
                setUserAgreementModalOpen(true);
            }}
            type="numeric"
            inputMode="number"
            inputStyle={{ border: "none", marginLeft: 8, marginRight: 8, borderRadius: 12, backgroundColor: '#EEF0F2' }}
            inputFocusStyle={{ borderWidth: 2, borderStyle: 'solid', borderColor: '#126FD6' }}
            autoSelect={true}
            regexCriteria={/^[0-9]*$/}
          />
        </div>
        <div className='flex items-center gap-4 mt-[60px]'>
          <Button
            type={ButtonType.SECONDARY}
            loading={loading}
            disabled={false}
            block={true}
            contentFontSize="text-sm"
            content="Try another way"
            onClick={async () => {
              setSMSCode('');
              setSmsPinKey(prev => prev + 1);
              onTryOthers();
            }}
          />
          <Button
            type={ButtonType.PRIMARY}
            loading={loading}
            disabled={smsCode.length !== 6}
            content="Log in"
            block={true}
            contentFontSize="text-sm"
            onClick={() => {
              setUserAgreementModalOpen(true);
            }}
          />
        </div>
      </div>
    </Modal>
    
    // <div className='flex flex-col h-full justify-between' onKeyDown={(e) => {
    //     if (e.key === 'Enter') {
    //       setUserAgreementModalOpen(true);
    //     }
    //   }}>
    //     <div className='flex flex-col'>
    //       <div className="text-2xl font-bold mb-4">Enter SMS verification code</div>
    //       <div className='text-sm mb-5'>
    //         Enter the code we sent over SMS to <span className="font-bold">{phone}</span>. 
    //         <a onClick={() => sendSMSCode({
    //           phone_number: phone,
    //         })}>Resend Code</a>
    //       </div>
    //       <PinInput
    //         key={smsPinKey}
    //         length={6}
    //         initialValue={smsCode}
    //         onComplete={(value, index) => {
    //             setSMSCode(value);
    //             setUserAgreementModalOpen(true);
    //         }}
    //         type="numeric"
    //         inputMode="number"
    //         inputStyle={{ border: "none", marginLeft: 8, marginRight: 8, borderRadius: 12, backgroundColor: '#EEF0F2' }}
    //         inputFocusStyle={{ borderWidth: 2, borderStyle: 'solid', borderColor: '#126FD6' }}
    //         autoSelect={true}
    //         regexCriteria={/^[0-9]*$/}
    //       />
    //     </div>
    //     <div className='flex items-center gap-4 mt-[60px]'>
    //       <Button
    //         type={ButtonType.SECONDARY}
    //         loading={loading}
    //         disabled={false}
    //         block={true}
    //         contentFontSize="text-sm"
    //         content="Try another way"
    //         onClick={async () => {
    //           setSMSCode('');
    //           setSmsPinKey(prev => prev + 1);
    //           onTryOthers();
    //         }}
    //       />
    //       <Button
    //         type={ButtonType.PRIMARY}
    //         loading={loading}
    //         disabled={smsCode.length !== 6}
    //         content="Log in"
    //         block={true}
    //         contentFontSize="text-sm"
    //         onClick={() => {
    //           setUserAgreementModalOpen(true);
    //         }}
    //       />
    //     </div>
    //   </div>
  );
};

export default PhoneVerificationModal;