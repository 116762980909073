import React, { useState } from 'react';
import { useSendCode } from '../../query/useSendCode';
import Button, { ButtonType } from './Button';
import Input from './Input';
import useAuthStore from '../../home8/store/auth';
import { axiosWithoutAuth } from '../../utils/axios';

type Props = {
  onCodeSend: () => void;
};

function SendCode({ onCodeSend }: Props) {
  const { mutateAsync } = useSendCode();
  const { email, setEmail, loading, setLoading } = useAuthStore();
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  const [showError, setShowError] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      console.log(email);

      const axios = axiosWithoutAuth();
      const { data } = await axios.post(
        'auth/send_code',
        {
          email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (data.code === 200) {
        onCodeSend();
      } else if (data.code === 418) {
        setShowError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-2xl font-bold mb-5">Welcome to Home8</div>
      <Input
        className="w-full bg-white mb-3"
        placeholder="Your email address"
        value={email}
        isError={false}
        required={true}
        onChange={(e) => {
          setShowError(false);
          setEmail((e.target as HTMLInputElement).value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && email !== '' && emailRegex.test(email) && !loading) {
            e.preventDefault();
            handleSubmit();
          }
        }}
      />
      {showError ? (
        <span className='text-xs font-normal'>
          <span className='text-[#C40612]'>*</span>
          <span>
       {' '} Home8 is currently available to a limited group of users. If you
            have any questions or need further information, please contact {' '}
            <a href='mailto:support@home8.ai'>support@home8.ai</a> for assistance.
          </span>
        </span>
      ) : (
        <Button
          type={ButtonType.PRIMARY}
          loading={loading}
          disabled={email === '' || !emailRegex.test(email)}
          content="Get verification code"
          block={true}
          contentFontSize="text-sm"
          onClick={handleSubmit}
        />
      )}
    </>
  );
}

export default SendCode;
