import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import AdminCreateProperty from "./pages/AdminCreateProperty";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AdminHome from "./pages/AdminHome";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AdminConversationHistory from "./pages/AdminConversationHistory";
import AdminHistory from "./pages/AdminHistory";
import Test from "./pages/Test";
import "rsuite/dist/rsuite-no-reset.min.css";
import HouseReport from "./pages/HouseReport";
import TestChatbot from "./pages/TestChatbot";
import HomeLogin from "./home8/Login";
import Properties from "./home8/Properties";
import PropertyDetail from "./home8/PropertyDetail";
import AdminPropertyDetail from "./pages/AdminPropertyDetail";
import Offer from "./home8/Offer";
import OfferIframe from "./home8/OfferIframe";
import "./index.css";
import OfferSignIframe from "./home8/OfferSignIframe";
import OfferViewIframe from "./home8/OfferViewIframe";
import AdminOfferReview from "./pages/AdminOfferReview";
import TransactionManagement from "./home8/TransactionManagement";
import ListingAgentGuide from "./home8/ListingAgentGuide";
import AdminCreatePropertyRedfin from "./pages/AdminCreatePropertyRedfin";
import HomePage from "./home8_new/pages/HomePage";
import ReportPage from "./home8_new/pages/ReportPage";
import PricingPage from "./home8_new/pages/PricingPage";
import ExpertChatPage from "./home8_new/pages/ExpertChatPage";
import CalculatorPage from "./home8_new/pages/CalculatorPage";
import PricingStrategyPage from "./home8_new/pages/PricingStrategyPage";
import ListingAgentCommunicationGuide from "./home8_new/pages/ListingAgentCommunicationGuide";
import MarketingPage from "./home8_new/pages/MarketingPage";
import ExampleReportPage from "./home8_new/pages/ExampleReportPage";

export const queryClient = new QueryClient();

function App() {
  const fallbackPath = `/home/marketing?redirect_uri=${location.href}`;
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/admin/properties"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminHome />
            //</RequireAuth>
          }
        ></Route>
        <Route
          path="/admin/create"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminCreateProperty />
            //</RequireAuth>
          }
        ></Route>
        <Route
          path="/admin/create-redfin"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminCreatePropertyRedfin />
            //</RequireAuth>
          }
        ></Route>
        <Route
          path="/admin/history/:propertyId?/:propertyAddr?"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminHistory />
            //</RequireAuth>
          }
        ></Route>
        <Route
          path="/admin/offer_reivew"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminOfferReview />
            //</RequireAuth>
          }
        ></Route>
        <Route
          path="/property/:propertyId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <PropertyDetail />
            //</RequireAuth>
          }
        />
        <Route
          path="/admin/property/:propertyId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminPropertyDetail />
            //</RequireAuth>
          }
        />
        <Route
          path="/admin/test/:propertyId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <Test />
            //</RequireAuth>
          }
        />
        <Route
          path="/admin/conversation/:conversationId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminConversationHistory />
            //</RequireAuth>
          }
        />
        <Route
          path="/house-report/:agentId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <HouseReport />
            //</RequireAuth>
          }
        />
        <Route path="/test-chatbot" element={<TestChatbot />} />
        <Route
          path="/home/all_properties"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <Properties />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/property/:propertyId"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <PropertyDetail />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/offers"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <Offer />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/offer_draft"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <OfferIframe />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/offer_sign"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <OfferSignIframe />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/offer_view"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <OfferViewIframe />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/transaction_management"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <TransactionManagement />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/la_guide"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <ListingAgentGuide />
            </RequireAuth>
          }
        ></Route>
        <Route path="/home/login" element={<HomeLogin />}></Route>
        {/* Redirect from root path to login path */}
        <Route
          path="/"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <HomePage />
            </RequireAuth>
          }
        />
        <Route
          path="/home/report/:propertyId"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <ReportPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/pricing"
          element={
            <PricingPage />
          }
        ></Route>
        <Route
          path="/home/expert_chat"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <ExpertChatPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/calculator/:propertyId"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <CalculatorPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/pricing_strategy/:propertyId"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <PricingStrategyPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/la_communication_guide"
          element={
            <RequireAuth fallbackPath={fallbackPath}>
              <ListingAgentCommunicationGuide />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/home/marketing"
          element={
            <MarketingPage />
          }
        ></Route>
        <Route
          path="/home/example_report"
          element={
            <ExampleReportPage />
          }
        ></Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
