import classNames from "classnames";
import { ReactComponent as Checked } from "../../static/Checked.svg";

type Props = {
  onClick: () => void;
  content: string;
  selected: boolean;
};

const SignupButton = ({ onClick, content }: Props) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "text-sm px-3 py-1 rounded-[14px] flex items-center gap-2 text-blue-500 bg-blue-50 cursor-pointer hover:bg-blue-100"
      )}
    >
      {content}
    </div>
  );
};

export default SignupButton;