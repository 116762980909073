import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import NoLoginTopBar from "../components/NoLoginTopBar";
import PropertySearchBar from "../components/PropertySearchBar";
import HomePageIllustration from "../components/HomePageIllustration";
import ContactExpertButton from "../components/ContactExpertButton";
import { usePropertySearchHistory } from "../../query/usePropertySearchHistory";
import Tag from "../components/Tag";
import Table, { TableColumn } from "../components/Table";
import { Suggestion } from "../components/PropertySearchModal";
import { OfferType, Property } from "../../store/agentInfo";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import OfferSection from "../components/OfferSection";
import TransactionSection from "../components/TransactionSection";
import DropdownSelect from "../components/DropdownSelect";
import LogRocket from "logrocket";
import { axiosWithAuth } from "../../utils/axios";
import Home8Logo from "../../static/Home8Logo.svg";
import Home8PropertyReport from "../../static/PropertyReport.svg";
import Home8PropertyReportExample from "../../static/ExamplePropertyReport.svg";
import Home8MarketingFirstPage from "../../static/MarketingFirstPage.svg";
import ButtonPlay from "../../static/ButtonPlay.svg";
import WhitePlayButton from "../../static/WhitePlayButton.svg";
import Home8MarketingBuyerAgentImage from "../../static/MarketingBuyerAgentImage.svg";
import Home8MarketingVideoIcon from "../../static/MarketingVideoIcon.svg";
import Home8MarketingOffer from "../../static/MarketingOffer.svg";
import Home8MarketingPricing from "../../static/MarketingPricing.svg";
import LinkArrow from "../../static/LinkArrow.svg";
import TestimoProfile1 from "../../static/TestimoProfile1.svg";
import TestimoProfile2 from "../../static/TestimoProfile2.svg";
import TestimoProfile3 from "../../static/TestimoProfile3.svg";
import TestimoProfile4 from "../../static/TestimoProfile4.svg";
import TestimoProfile5 from "../../static/TestimoProfile5.svg";
import TestimoProfile6 from "../../static/TestimoProfile6.svg";
import Home8StartHomeBuying from "../../static/StartHomeBuying.svg";
import EmailInputModal from "../components/EmailInputModal";

interface PricingCardProps {
  index: number;
  plan: string;
  title: string;
  price: string;
  features: string[];
  calendlyLink: string;
}

interface Testimonial {
  quote: string;
  authors: string;
  location: string;
  imageUrl: string;
}

const testimonials: Testimonial[] = [
  {
    quote:
      "Your product is a game-changer in the real estate space—professional, efficient, and genuinely focused on delivering the essential tools I need to purchase a home, like property analysis reports and free offer writing. In addition to the software, the pricing strategy service from your real estate team is incredibly valuable.",
    authors: "Wei Z. & Huan C.",
    location: "Santa Clara, CA",
    imageUrl: TestimoProfile1,
  },
  {
    quote:
      "The property reports provided me with comprehensive insights into the homes, including inspection and repair costs, legal square footage, permit history, investment potential, comparable sales, market trends, and much more. This powerful tool is incredibly user-friendly, saving me significant time and effort.",
    authors: "Molly.S & James S.",
    location: "Belmont, CA",
    imageUrl: TestimoProfile2,
  },
  {
    quote:
      "I’m quite experienced with the home buying process. All I needed was a person or tool to help me draft and send offers to listing agents. Home8 helped me prepare complete offer packages, including writing and signing offers, signing disclosures, providing proof of funds, pre-approval letters, and even love letters. And the best part? All of these services are free!",
    authors: "Jasmin W. & Robert W.",
    location: "San Francisco, CA",
    imageUrl: TestimoProfile3,
  },
  {
    quote:
      "This was our first time buying a home in the U.S., so we chose to work with Home8's full-service agent. The agent was incredibly kind and had an in-depth understanding of the Bay Area housing market. She took the time to listen to our family's needs and considered things from our perspective. In the end, we bought a home that my husband, my child, and I all love!",
    authors: "Aditya G. & Swathi G.",
    location: "San Jose, CA",
    imageUrl: TestimoProfile4,
  },
  {
    quote:
      "Our goal was to purchase an investment property, and Home8's agent team brought extensive expertise in this area. They helped me identify which locations had the highest potential and advised on the type of property that would yield the best ROI. They also helped me connect with a reliable property management company.",
    authors: "Nam N. & Trang N.",
    location: "Milpitas, CA",
    imageUrl: TestimoProfile5,
  },
  {
    quote:
      "I purchased the condo I had been renting, so I only needed transaction services. Home8 provided exceptional support every step of the way, from handling the deposit to navigating escrow, coordinating the notary signing, completing the final walkthrough, and ultimately helping me become the official new owner.",
    authors: "Julian W.",
    location: "Oakland, CA",
    imageUrl: TestimoProfile6,
  },
  // Add more testimonials...
];

// In your MarketingPage.tsx
const pricingData = [
  {
    plan: "Plan 1",
    title: "Real estate data, analysis & tools",
    price: "Free",
    calendlyLink: "https://calendly.com/home8-support/sales-diy-home-buying",
    features: [
      "Best for highly experienced buyer",
      "Comprehensive property analysis",
      "Offer package preparation",
      "Tutorials on how to work with listing agent",
    ],
  },
  {
    plan: "Plan 2",
    title: "Pricing strategy & negotiation",
    price: "$7,999",
    calendlyLink: "https://calendly.com/home8-support/sales-price-negotiation-service",
    features: [
      "Best for experienced buyer",
      "Customized pricing strategy",
      "Negotiation with listing agent",
      "House closing until you become the owner",
    ],
  },
  {
    plan: "Plan 3",
    title: "Full service buyer agent",
    price: "1% commission",
    calendlyLink: "https://calendly.com/home8-support/sales-full-service-buyer-agent",
    features: [
      "Best for first time buyer",
      "Guidance from a dedicated local buyer agent",
      "Our agent supports you through every step of your home-buying journey",
    ],
  },
];

const Footer = () => {
  const footerSections = [
    {
      title: "Product",
      links: [
        { text: "Property report", href: "#" },
        { text: "Write offer", href: "#" },
      ],
    },
    {
      title: "Service",
      links: [
        { text: "Pricing strategy and negotiation", href: "#" },
        { text: "Full service buyer agent", href: "#" },
        { text: "Transaction management", href: "#" },
      ],
    },
    {
      title: "Resources",
      links: [{ text: "How to work with listing agent", href: "#" }],
    },
    {
      title: "Home8",
      links: [
        { text: "About us", href: "#" },
        { text: "Contact", href: "#" },
        { text: "Privacy and terms", href: "#" },
      ],
    },
  ];

  return (
    <footer className="flex justify-center items-center w-full max-w-[1440px] mx-auto bg-[#58763C] text-white">
      <div className="max-w-[1440px] mx-auto py-10 px-[80px]">
        <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-0">
          {footerSections.map((section, index) => (
            <div key={index}>
              <h3 className="text-white/60 font-open-sans text-base font-bold mb-3">
                {section.title}
              </h3>
              <ul className="space-y-3">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a
                      href={link.href}
                      className="text-white hover:text-gray-200 font-open-sans text-base font-normal no-underline"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

const MarketingPage = () => {
  const { t } = useTranslation();
  const marketingScrollRef = useRef<HTMLDivElement>(null);
  const [pricingHeight, setPricingHeight] = useState<number>(0);
  const pricingCardsRef = useRef<HTMLDivElement>(null);
  const [titleSectionHeight, setTitleSectionHeight] = useState<number>(0);
  const [priceSectionHeight, setPriceSectionHeight] = useState<number>(0);
  const [showEmailModal, setShowEmailModal] = useState(false); // Add this state
  const priceRefs = useRef<(HTMLDivElement | null)[]>([]);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);
  const lastSectionRef = useRef<HTMLDivElement>(null);
  const footerSectionContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Get all heights
    const heights = titleRefs.current
      .filter((ref): ref is HTMLDivElement => ref !== null)
      .map((ref) => ref.offsetHeight);

    // Find the maximum height
    const maxHeight = Math.max(...heights);

    // Set the maximum height
    setTitleSectionHeight(maxHeight);

    if (lastSectionRef.current && footerSectionContainerRef.current) {
      console.log("debug")
      const height = footerSectionContainerRef.current.offsetHeight;
      lastSectionRef.current.style.height = `${height}px`
    }

  }, []); // Run once on mount

  useEffect(() => {
    if (pricingCardsRef.current) {
      setPricingHeight(pricingCardsRef.current.offsetHeight);

      const handleResize = () => {
        if (pricingCardsRef.current) {
          setPricingHeight(pricingCardsRef.current.offsetHeight);
        }
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    const heights = priceRefs.current
      .filter((ref): ref is HTMLDivElement => ref !== null)
      .map((ref) => ref.offsetHeight);

    const maxHeight = Math.max(...heights);
    setPriceSectionHeight(maxHeight);
  }, []);

  const calculateImageHeight = () => {
    const viewportHeight = window.innerHeight;
    const topBarHeight = 72; // TopBar height
    const titleHeight = 28; // Approximate height for "PLANS & PRICING" text
    const containerPadding = 40; // Total vertical padding
    const imagePadding = 40; // Total vertical padding

    // Available height = viewport - (topbar + title + pricing cards + padding)
    const availableHeight =
      viewportHeight -
      (topBarHeight +
        titleHeight +
        pricingHeight +
        containerPadding +
        imagePadding);

    // Set a minimum height to prevent the image from becoming too small
    return Math.min(availableHeight, 247); // available space with minimum 248px
  };

  const PricingCard = ({
    plan,
    title,
    price,
    features,
    index,
    calendlyLink,
  }: PricingCardProps) => {
    return (
      <div className="rounded-[20px] border border-gray-200 p-6 flex flex-col">
        <div
          ref={(el) => (titleRefs.current[index] = el)}
          className="flex justify-between items-start mb-3"
          style={{
            minHeight: titleSectionHeight ? `${titleSectionHeight}px` : "auto",
          }}
        >
          <div className="text-gray-300 text-base font-open-sans font-extrabold">
            {title}
          </div>
          <div className="text-[#6DA438] text-base font-open-sans font-extrabold">
            {plan}
          </div>
        </div>
        <div
          ref={(el) => (priceRefs.current[index] = el)}
          className="text-[32px] leading-[44px] font-bold text-gray-900 mb-5"
          style={{
            minHeight: priceSectionHeight ? `${priceSectionHeight}px` : "auto",
          }}
        >
          {price}
        </div>

        <div className="flex flex-col md:flex-row items-center gap-2 md:justify-between">
          <button
            className="w-full md:w-auto bg-blue-50 text-blue-500 px-6 py-3 text-base font-open-sans font-semibold rounded-[32px] hover:text-blue-700 h-[46px] flex items-center justify-center"
            onClick={() => {
              window.open(calendlyLink, '_blank');
            }}
          >
            Contact sales
          </button>
          {/* <button className="w-full md:w-auto bg-blue-500 text-white pr-6 pl-3 py-3 rounded-[40px] hover:bg-blue-700 transition-colors flex items-center justify-center">
            <img
              src={WhitePlayButton}
              alt="Video Icon"
              className="w-6 h-6 mr-[6px]"
            />
            <span className="font-open-sans font-semibold text-base">
              How it works
            </span>
          </button> */}
        </div>

        <ul className="space-y-4 mt-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start gap-2">
              •{" "}
              <span className="text-gray-900 text-base font-open-sans font-normal">
                {feature}
              </span>
            </li>
          ))}
        </ul>

        <a
          href="#"
          className="text-blue-500 mt-7 flex items-center no-underline"
        >
          <span className="text-base font-open-sans font-normal">
            Read full feature list
          </span>
          <img src={LinkArrow} alt="Arrow Right" className="w-6 h-6 ml-1" />
        </a>
      </div>
    );
  };

  return (
    <div className="min-h-screen w-full flex flex-col">
      <div className="fixed top-0 left-0 right-0 z-50">
        <NoLoginTopBar onSignupClick={() => setShowEmailModal(true)} />
      </div>

      <div className="flex flex-col mt-[72px]">
        {/* Snap-scrolling sections container */}
        <div className="scroll-snap-container mx-auto pl-[calc((100vw-1440px)/2)] pr-[calc((100vw-1440px)/2)]">
          <section className="scroll-snap-section">
            <div className="flex justify-center items-center h-[calc(100vh-72px)] min-h-[696px]">
              <div className="max-w-[1440px] w-full px-5 my-[100px] relative">
                <div className="flex flex-col items-center text-center">
                  <div>
                    <img
                      src={Home8MarketingFirstPage}
                      alt="House with couple illustration"
                      className="w-auto h-[calc(100vh-72px-277px-200px)] rounded-[20px] min-h-[219px]"
                    />
                  </div>

                  <div className="text-[32px] leading-[36px] font-bold text-gray-900 mb-8 mt-[60px] font-domine">
                    Tired of overpaying for mediocre buyer agents?
                    <br />
                    We provide professional buyer agent services, starting at
                    $0.
                  </div>

                  <div className="text-gray-600 text-xl font-open-sans font-light mb-8">
                    Free property analysis, free offer making tools, buyer agent
                    services
                  </div>

                  <div className="flex items-center gap-4">
                    {/* <button
                      className="bg-blue-50 text-blue-500 pr-6 pl-3 py-3 text-base font-open-sans font-semibold rounded-[32px] hover:text-blue-700 h-[46px] flex items-center justify-center"
                      onClick={() => {}}
                    >
                      <img
                        src={ButtonPlay}
                        alt="Play"
                        className="w-6 h-6 mr-[6px]"
                      />
                      How it works
                    </button> */}
                    <button 
                      className="bg-blue-500 text-white px-6 py-3 text-base font-open-sans font-semibold rounded-[32px] hover:bg-blue-700 transition-colors h-[46px] flex items-center justify-center"
                      onClick={() => setShowEmailModal(true)}
                    >
                      Sign up for free
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="scroll-snap-section">
            <div className="grid grid-cols-16 px-5 py-12 gap-[40px] min-h-[calc(100vh-72px)] items-center">
              {/* Right section can span columns 7-12 */}
              <div className="col-span-16 sm:col-start-9 sm:col-end-16 md:col-start-9 md:col-end-16 lg:col-start-9 lg:col-end-16 xl:col-start-9 xl:col-end-16 space-y-6 order-first sm:order-last md:order-last lg:order-last xl:order-last">
                <img
                  src={Home8PropertyReport}
                  alt="Property Report Example"
                  className="w-full h-auto rounded-[20px] max-w-full"
                />
              </div>

              {/* Left section spanning columns 1-6 */}
              <div className="col-span-16 sm:col-start-2 sm:col-end-9 md:col-start-2 md:col-end-9 lg:col-start-2 lg:col-end-9 xl:col-start-2 xl:col-end-9 order-last sm:order-first md:order-first lg:order-first xl:order-first flex items-center">
                <div>
                  <div className="text-[40px] leading-[46px] font-bold text-gray-900 mb-3 font-domine">
                    Free comprehensive property analysis
                  </div>

                  <ul className="mb-10">
                    <li className="text-2xl font-open-sans font-light text-gray-900">
                      • Extensive data on properties, locations, and the
                      market--all in one click
                    </li>
                    <li className="text-2xl font-open-sans font-light text-gray-900">
                      • Uncover the pros and cons of each property
                      transparently, avoid risky purchase
                    </li>
                  </ul>

                  <button 
                    onClick={() => window.open('/home/example_report', '_blank')}
                    className="bg-blue-500 text-white pr-6 pl-3 py-3 rounded-[40px] hover:bg-blue-700 transition-colors flex items-center"
                  >
                    <img
                      src={Home8PropertyReportExample}
                      alt="Report Example"
                      className="w-6 h-6 mr-[6px]"
                    />
                    <span className="font-open-sans font-semibold text-base">
                      See a property report example
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="scroll-snap-section">
            <div className="grid grid-cols-16 px-5 py-12 gap-[40px] min-h-[calc(100vh-72px)] items-center">
              {/* Right section can span columns 7-12 */}
              <div className="col-span-16 sm:col-start-9 sm:col-end-16 md:col-start-9 md:col-end-16 lg:col-start-9 lg:col-end-16 xl:col-start-9 xl:col-end-16 space-y-6 order-first sm:order-last md:order-last lg:order-last xl:order-last">
                <div>
                  <div className="text-[40px] leading-[46px] font-bold text-gray-900 mb-3 font-domine">
                    Free offer prep tools
                  </div>

                  <ul className="mb-10">
                    <li className="text-2xl font-open-sans font-light text-gray-900">
                      • Free comprehensive offer packages: Includes signed
                      offers, signed disclosures, pre-qualifications, and
                      letters of intent
                    </li>
                    <li className="text-2xl font-open-sans font-light text-gray-900">
                      • A real estate expert reviews your offers for
                      accuracy—completely free
                    </li>
                  </ul>

                  {/* <button className="bg-blue-500 text-white pr-6 pl-3 py-3 rounded-[40px] hover:bg-blue-700 transition-colors flex items-center">
                    <img
                      src={WhitePlayButton}
                      alt="Video Icon"
                      className="w-6 h-6 mr-[6px]"
                    />
                    <span className="font-open-sans font-semibold text-base">
                      How it works
                    </span>
                  </button> */}
                </div>
              </div>

              {/* Left section spanning columns 1-6 */}
              <div className="col-span-16 sm:col-start-2 sm:col-end-9 md:col-start-2 md:col-end-9 lg:col-start-2 lg:col-end-9 xl:col-start-2 xl:col-end-9 order-last sm:order-first md:order-first lg:order-first xl:order-first flex items-center">
                <img
                  src={Home8MarketingOffer}
                  alt="Offer Image"
                  className="w-full h-auto rounded-[20px] max-w-full"
                />
              </div>
            </div>
          </section>

          <section className="scroll-snap-section">
            <div className="grid grid-cols-16 px-5 py-12 gap-[40px] min-h-[calc(100vh-72px)] items-center">
              {/* Right section can span columns 7-12 */}
              <div className="col-span-16 sm:col-start-9 sm:col-end-16 md:col-start-9 md:col-end-16 lg:col-start-9 lg:col-end-16 xl:col-start-9 xl:col-end-16 space-y-6 order-first sm:order-last md:order-last lg:order-last xl:order-last">
                <img
                  src={Home8MarketingBuyerAgentImage}
                  alt="Buyer Agent Image"
                  className="w-full h-auto rounded-[20px]"
                />
              </div>

              {/* Left section spanning columns 1-6 */}
              <div className="col-span-16 sm:col-start-2 sm:col-end-9 md:col-start-2 md:col-end-9 lg:col-start-2 lg:col-end-9 xl:col-start-2 xl:col-end-9 order-last sm:order-first md:order-first lg:order-first xl:order-first flex items-center">
                <div>
                  <div className="text-[40px] leading-[46px] font-bold text-gray-900 mb-3 font-domine">
                    Affordable high quality buyer agent services
                  </div>

                  <ul className="mb-10">
                    <li className="text-2xl font-open-sans font-light text-gray-900">
                      • 10 years of experience, 1,500+ home transactions
                    </li>
                    <li className="text-2xl font-open-sans font-light text-gray-900">
                      • Different plans to suit different buyers' needs,
                      starting at $0, with a maximum of 1% commission.
                    </li>
                  </ul>

                  <button 
                    className="bg-blue-500 text-white pr-6 pl-3 py-3 rounded-[40px] hover:bg-blue-700 transition-colors flex items-center"
                    onClick={() => window.open('https://calendly.com/home8-support/sales-full-service-buyer-agent', '_blank')}
                  >
                    <img
                      src={Home8MarketingVideoIcon}
                      alt="Video Icon"
                      className="w-6 h-6 mr-[6px]"
                    />
                    <span className="font-open-sans font-semibold text-base">
                      Free intro session with Home8 agent
                    </span>
                  </button>

                  <div className="text-gray-600 text-sm font-open-sans font-light mt-2">
                    No commitment required in this intro session—no need to sign
                    a Buyer Representation and Brokerage Contract (BRBC) with
                    Home8.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="scroll-snap-section">
            <div className="min-h-[calc(100vh-72px)]">
              <div className="max-w-[1440px] w-full p-5 relative">
                <div className="flex justify-center text-gray-300 font-extrabold text-xl font-open-sans">
                  PLANS & PRICING
                </div>
                <div className="flex justify-center py-5">
                  <img
                    src={Home8MarketingPricing}
                    alt="Pricing"
                    className="w-auto rounded-[20px] min-h-[247px]"
                    style={{ height: `${calculateImageHeight()}px` }}
                  />
                </div>
                <div
                  ref={pricingCardsRef}
                  className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mx-auto"
                >
                  {pricingData.map((plan, index) => (
                    <PricingCard
                      key={index}
                      index={index}
                      plan={plan.plan}
                      title={plan.title}
                      price={plan.price}
                      calendlyLink={plan.calendlyLink}
                      features={plan.features}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="scroll-snap-section">
            <div className="min-h-[calc(100vh-72px)]">
              <div className="max-w-[1440px] w-full p-5 relative">
                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-5">
                  {testimonials.map((testimonial, index) => (
                    <div
                      key={index}
                      className={classNames(
                        "rounded-[20px] p-6 flex flex-col",
                        index % 2 === 0 ? "bg-[#F7C42B33]" : "bg-[#6DA43833]" // Green and yellow backgrounds
                      )}
                    >
                      <div className="mb-12">
                        <span
                          className={classNames(
                            "text-[36px] text-rozh-one",
                            index % 2 === 0
                              ? "text-[#F7C42B]"
                              : "text-[#6DA438]"
                          )}
                        >
                          "
                        </span>
                        <p className="text-gray-900 text-base font-open-sans font-normal">
                          {testimonial.quote}
                        </p>
                      </div>

                      <div className="mt-auto flex items-center gap-4">
                        <img
                          src={testimonial.imageUrl}
                          alt={testimonial.authors}
                          className="w-15 h-15 rounded-full object-cover"
                        />
                        <div>
                          <p className="text-gray-900 font-bold text-base font-open-sans">
                            {testimonial.authors}
                          </p>
                          <p className="text-gray-600 text-sm font-open-sans font-normal">
                            {testimonial.location}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="scroll-snap-section">
            <div ref={lastSectionRef} className="flex flex-col h-fit overflow-y-auto">
              <div ref={footerSectionContainerRef} className="flex justify-center items-center h-[calc(100vh-72px)] min-h-[601px] shrink-0">
                <div className="max-w-[1440px] w-full px-5 my-[100px] relative">
                  <div className="flex flex-col items-center text-center">
                    <div>
                      <img
                        src={Home8StartHomeBuying}
                        alt="House with couple illustration"
                        className="w-auto h-[calc(100vh-72px-182px-200px)] rounded-[20px] min-h-[219px]"
                      />
                    </div>

                    <div className="text-[32px] leading-[44px] font-light text-gray-900 mb-8 mt-10 font-open-sans">
                      Start your home buying journey with Home8?
                    </div>

                    <button 
                      className="bg-blue-500 text-white px-6 py-3 text-base font-open-sans font-semibold rounded-[32px] hover:bg-blue-700 transition-colors h-[46px] flex items-center justify-center"
                      onClick={() => setShowEmailModal(true)}
                    >
                      Signup for free
                    </button>
                  </div>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
        </section>
        </div>
      </div>

      {showEmailModal && (
        <EmailInputModal
          isModalOpen={showEmailModal}
          onModalClose={() => setShowEmailModal(false)}
        />
      ) }
    </div>
  );
};

export default MarketingPage;
