import React, { useState } from 'react';
import { useSendCode } from '../../query/useSendCode';
import Button, { ButtonType } from './Button';
import Input from './Input';
import useAuthStore from '../../home8/store/auth';
import PinInput from 'react-pin-input';
import { NONAME } from 'dns';
import { useInputCode } from '../../query/useInputCode';
import PhoneNumberInputModal from './PhoneNumberInputModal';
import UserAgreementModal from './UserAgreementModal';
import PhoneVerificationModal from './PhoneVerificationModal';

type Props = {
  onTryOthers: () => void;
};

function InputCode({ onTryOthers }: Props) {
  const { mutateAsync: sendCode } = useSendCode();
  const {mutateAsync: inputCode} = useInputCode()
  const { code, setCode, setSMSCode, loading, setLoading, email } = useAuthStore()
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false);
  const [userAgreementModalOpen, setUserAgreementModalOpen] = useState(false);
  const [pinKey, setPinKey] = useState(0); 

  const handleLogin = async () => {
    if (code.length !== 6) return;
    setLoading(true);
    try {
      const result = await inputCode({
        email: email,
        code: code,
      });
      console.log(result)
      if (result?.needPhoneNumber) {
        setShowPhoneNumberModal(true);
      } else {
        setCode('');
        setPinKey(prev => prev + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  console.log(code)
  return (
    <>
    <div className='flex flex-col h-full justify-between' onKeyDown={(e) => {
      if (e.key === 'Enter') {
        handleLogin();
      }
    }}>
      <div className='flex flex-col'>
        <div className="text-2xl font-bold mb-4">Enter email verification code</div>
        <div className='text-sm mb-5'>
          Enter the code we sent over email to <span className="font-bold">{email}</span>. 
          <a onClick={() => sendCode({
            email,
          })}>Resend Code</a>
        </div>
        <PinInput
          key={pinKey}
          length={6}
          initialValue={code}
          onComplete={(value, index) => {
              setCode(value);
              handleLogin();
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ border: "none", marginLeft: 8, marginRight: 8, borderRadius: 12, backgroundColor: '#EEF0F2' }}
          inputFocusStyle={{ borderWidth: 2, borderStyle: 'solid', borderColor: '#126FD6' }}
          autoSelect={true}
          regexCriteria={/^[0-9]*$/}
        />
      </div>
      <div className='flex items-center gap-4 mt-[60px]'>
        <Button
          type={ButtonType.SECONDARY}
          loading={loading}
          disabled={false}
          block={true}
          contentFontSize="text-sm"
          content="Try another way"
          onClick={async () => {
            setCode('');
            setPinKey(prev => prev + 1);
            onTryOthers();
          }}
        />
        <Button
          type={ButtonType.PRIMARY}
          loading={loading}
          disabled={code.length !== 6}
          content="Log in"
          block={true}
          contentFontSize="text-sm"
          onClick={handleLogin}
        />
      </div>
    </div>

    {/* Phone Number Modal */}
    {showPhoneNumberModal && (
        <PhoneNumberInputModal
          isModalOpen={showPhoneNumberModal}
          onModalClose={() => setShowPhoneNumberModal(false)}
          setShowPhoneVerificationModal={setShowPhoneVerificationModal}
        />
    ) }
    {/* Phone Verification Modal */}
    {showPhoneVerificationModal && (
        <PhoneVerificationModal
          isModalOpen={showPhoneVerificationModal}
          onModalClose={() => {
            setShowPhoneVerificationModal(false);
            setSMSCode('');  // Add this line to clear SMS code
          }}
          setUserAgreementModalOpen={setUserAgreementModalOpen}
          onTryOthers={() => {
            setShowPhoneVerificationModal(false);
            setShowPhoneNumberModal(true);
          }}
        />
    ) }
    {/* User Agreement Modal */}
    {userAgreementModalOpen && (
        <UserAgreementModal
          isModalOpen={userAgreementModalOpen}
          onModalClose={() => {
            setUserAgreementModalOpen(false);
            setCode('');
            setPinKey(prev => prev + 1);
            setSMSCode('');
            setShowPhoneVerificationModal(false);
            setShowPhoneNumberModal(false);
            onTryOthers();
          }}
        />
    ) }
    </>
  );
}

export default InputCode;