import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { ButtonType } from "./Button";
import Button from "./Button";
import { useHomeUser } from "../../query/useHomeUser";
import { useHomeRequestProperty } from "../../query/useHomeRequestProperty";

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  onRequestSent: () => void;
  address: string;
};

const PropertyRequestNoteModal = ({
  isModalOpen,
  onModalClose,
  onRequestSent,
  address,
}: Props) => {
  const { t } = useTranslation();
  const { data: userData } = useHomeUser();
  const { mutateAsync: requestProperty } = useHomeRequestProperty();

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={false}
    >
      <div className="flex flex-col items-start justify-start h-full w-full text-sm">
        <div className="flex flex-col items-start p-5 w-full">
          <div className="text-sm font-bold text-gray-900">{t("newPropertyRequestNoteModalTitle")}</div>
          <div className="text-sm font-normal text-gray-900 mt-2">
            {t("newPropertyRequestNoteModalSubtitlePartOne")}
            <span className="text-sm font-bold">{address}</span>
            {t("newPropertyRequestNoteModalSubtitlePartTwo")}
            <span className="text-sm font-bold text-green-600">{t("propertyStatusOnSaleLabel")}</span>
          </div>
          <div className="flex mt-10 w-full justify-end">
            <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("requestPropertyReportSendNote")}
              onClick={async () => {
                requestProperty({
                  address: address,
                  email: userData.email,
                });
                onModalClose();
                onRequestSent()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PropertyRequestNoteModal;
