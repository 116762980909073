import { ReactNode, useEffect } from "react";
import { ReactComponent as Close } from "../../static/Close.svg";
import RequestSentBg from "../../static/RequestSentBg.svg";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Button, { ButtonType } from "./Button";
import { useHomeRequestProperty } from "../../query/useHomeRequestProperty";
import { useHomeUser } from "../../query/useHomeUser";

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  address: string;
  onRequestSent: () => void;
};

const PropertySearchNoMatchModal = ({
  isModalOpen,
  onModalClose,
  address,
  onRequestSent,
}: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: requestProperty } = useHomeRequestProperty();
  const { data: userData } = useHomeUser();
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={false}
    >
      <div className="flex flex-col items-center justify-start h-full w-full p-5 text-sm">
        <Close
          className="absolute top-5 right-5 cursor-pointer"
          onClick={onModalClose}
        />
        <div className="flex flex-col text-left gap-2 w-full mb-10">
          <div className="text-sm font-bold text-left">{t("newPropertySearchNoMatchTitle")}</div>
          <div className="text-sm font-bold text-gray-600">{t("newPropertySearchNoMatchSubtitle")} <span className="text-gray-900">{address}</span></div>
        </div>
        <div className="w-full flex justify-end">
          <Button
            type={ButtonType.PRIMARY}
            loading={false}
            disabled={false}
            content={t("requestPropertyReport")}
            onClick={async () => {
              requestProperty({
                address: address,
                email: userData.email,
              });
              onModalClose();
              onRequestSent();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PropertySearchNoMatchModal;
