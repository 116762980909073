import { useQuery } from "@tanstack/react-query";
import { axiosAdminWithoutAuth } from "../utils/axios";

export const useExampleHomePropertyWithProblemOverview = (lang?: string) => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ["home8_example_propertyWithProblemOverview", lang],
        queryFn: async () => {
            try {
                const data = {
                    lang: lang || "en-US",
                }
                const result = await axios.post('get_example_property_with_problem_overview', data)
                console.log(result.data)
                return result.data
            } catch (e: any) {
                return null;
            }
        },
        refetchOnWindowFocus: false,
    });
};