import { useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import { useCompareSalesData } from "../../query/useCompareSalesData";
import { useHomePropertyWithProblemOverview } from "../../query/useHomePropertyWithProblemOverview";
import { propertyStatusMap } from "../utils/propertyStatus";
import { PropertyWithProblemOverview } from "../../store/agentInfo";
import moment from "moment";
import Button, { ButtonType } from "../components/Button";
import { useTranslation } from "react-i18next";
import { ReactComponent as Video } from "../../static/Video.svg";
import { ReactComponent as Payment } from "../../static/Payment.svg";
import Table, { TableColumn } from "../components/Table";
import ContactExpertButton from "../components/ContactExpertButton";
import { useHomeCreateCompareSalesRequest } from "../../query/useHomeCreateCompareSalesRequest";
import NotificationModal from "../components/NotificationModal";
import { useState } from "react";
import { ReactComponent as Success } from "../../static/Success.svg";

enum CompareSalesStatus {
  NONE,
  PENDING,
  FINISHED,
}

type CompareSalesPropertyData = {
  mls_listing_id: string;
  address: string;
  bedrooms: number;
  full_bathrooms: number;
  half_bathrooms: number;
  lot_size: number;
  living_sqft: number;
  on_market_date: number;
  off_market_date: number;
  original_listing_price: number;
  close_price: number;
  expert_note: string;
};

const PricingStrategyPage = () => {
  const { propertyId } = useParams();
  const { t, i18n } = useTranslation();
  const { data, refetch } = useCompareSalesData({
    propertyId: propertyId ?? "",
  });
  const { data: propertyData } = useHomePropertyWithProblemOverview(propertyId);
  const propertyProblemOverviewInfo =
    propertyData as PropertyWithProblemOverview;
  const { mutate: createRequest } = useHomeCreateCompareSalesRequest();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columnsUpper: TableColumn[] = [
    {
      columnName: t("newPricingStrategyPageHistoricalHighestColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => (
        <div className="flex gap-2 items-center max-w-[600px]">
          <div className="min-w-0">
            <div className="text-sm font-normal text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden">
              {data.address}
            </div>
            <div className="text-xs font-normal text-gray-600">
              {data.bedrooms}b{data.full_bathrooms + data.half_bathrooms * 0.5}b
              | {data.living_sqft} {t("sqftLabel")}{" "}
              {Boolean(data.lot_size) &&
                `| ${data.lot_size} 
              ${t("sqftLabel")} lot`}
            </div>
            <div className="text-xs font-normal text-gray-600">
              Home8 expert note:
              {data.expert_note}
            </div>
          </div>
        </div>
      ),
      className: "grow min-w-[400px]",
    },
    {
      columnName: t("newPricingStrategyPageSoldOnColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => {
        if (!data.off_market_date) {
          return <></>;
        } else {
          return (
            <div className="text-sm">
              {moment
                .unix(data.off_market_date)
                .toDate()
                .toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
            </div>
          );
        }
      },
      className: "grow",
    },
    {
      columnName: t("newPricingStrategyPageDaysOnMarketColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => {
        if (!data.off_market_date || !data.on_market_date) {
          return <></>;
        } else {
          const onMarketDate = moment.unix(data.on_market_date);
          const offMarketDate = moment.unix(data.off_market_date);
          return (
            <div className="text-sm">
              {offMarketDate.diff(onMarketDate, 'd')}
            </div>
          );
        }
      },
      className: "grow",
    },
    {
      columnName: t("newPricingStrategyPageListingPriceColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => (
        <div className="text-sm">
          {data.original_listing_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
      ),
      className: "grow",
    },
    {
      columnName: t("newPricingStrategyPageSoldPriceColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => {
        if (!data.close_price) {
          return <></>;
        } else {
          return (
            <div className="text-sm font-bold">
              {data.close_price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        }
      },
      className: "grow",
    },
  ];

  const columnsLower: TableColumn[] = [
    {
      columnName: t("newPricingStrategyPageComparableSalesColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => (
        <div className="flex gap-2 items-center max-w-[600px]">
          <div className="min-w-0">
            <div className="text-sm font-normal text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden">
              {data.address}
            </div>
            <div className="text-xs font-normal text-gray-600">
              {data.bedrooms}b{data.full_bathrooms + data.half_bathrooms * 0.5}b
              | {data.living_sqft} {t("sqftLabel")}{" "}
              {Boolean(data.lot_size) &&
                `| ${data.lot_size} 
              ${t("sqftLabel")} lot`}
            </div>
            <div className="text-xs font-normal text-gray-600">
              Home8 expert note:
              {data.expert_note}
            </div>
          </div>
        </div>
      ),
      className: "grow min-w-[400px]",
    },
    {
      columnName: t("newPricingStrategyPageSoldOnColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => {
        if (!data.off_market_date) {
          return <></>;
        } else {
          return (
            <div className="text-sm">
              {moment
                .unix(data.off_market_date)
                .toDate()
                .toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
            </div>
          );
        }
      },
      className: "grow",
    },
    {
      columnName: t("newPricingStrategyPageDaysOnMarketColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => {
        if (!data.off_market_date || !data.on_market_date) {
          return <></>;
        } else {
          const onMarketDate = moment.unix(data.on_market_date);
          const offMarketDate = moment.unix(data.off_market_date);
          return (
            <div className="text-sm">
              {offMarketDate.diff(onMarketDate, 'd')}
            </div>
          );
        }
      },
      className: "grow",
    },
    {
      columnName: t("newPricingStrategyPageListingPriceColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => (
        <div className="text-sm">
          {data.original_listing_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
      ),
      className: "grow",
    },
    {
      columnName: t("newPricingStrategyPageSoldPriceColumnTitle"),
      renderer: (data: CompareSalesPropertyData) => {
        if (!data.close_price) {
          return <></>;
        } else {
          return (
            <div className="text-sm font-bold">
              {data.close_price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        }
      },
      className: "grow",
    },
  ];

  if (!propertyId || !data || !propertyData) {
    return <></>;
  }

  const createdTime = moment(propertyProblemOverviewInfo.property.create_time);
  const dateDiff = moment().diff(createdTime, "day");

  const {
    compare_sales_expired_time,
    compared_highest_property,
    compared_properties,
    estimate_price_expired_time,
    expert_estimate_price,
    expert_estimate_price_min,
    status,
  } = data;
  console.log(data);

  const comparedHighestProperty =
    moment().isBefore(moment.unix(compare_sales_expired_time)) ||
    !compare_sales_expired_time
      ? compared_highest_property
      : null;

  const comparedProperties =
    moment().isBefore(moment.unix(compare_sales_expired_time)) ||
    !compare_sales_expired_time
      ? compared_properties
      : [];

  const estimatePrice =
    expert_estimate_price &&
    (moment().isBefore(moment.unix(estimate_price_expired_time)) ||
      !estimate_price_expired_time)
      ? expert_estimate_price
      : 0;

  const estimatePriceMin =
    expert_estimate_price_min &&
    (moment().isBefore(moment.unix(estimate_price_expired_time)) ||
      !estimate_price_expired_time)
      ? expert_estimate_price_min
      : 0;

  const statusData = propertyStatusMap[
    propertyProblemOverviewInfo.property
      .status as keyof typeof propertyStatusMap
  ] ?? {
    label: "For sale",
    color: "green-600",
  };

  console.log(data);
  console.log(estimate_price_expired_time);
  console.log(moment.unix(estimate_price_expired_time));
  console.log(moment().isBefore(moment.unix(estimate_price_expired_time)));
  console.log(data && data.user_requested && moment().isBefore(moment.unix(compare_sales_expired_time)));

  return (
    <div className="flex flex-col items-center h-full">
      <TopBar />
      <NotificationModal
        isModalOpen={isModalOpen}
        onModalClose={() => {
          setIsModalOpen(false);
          refetch();
        }}
        title="Request sent"
        subTitle="Home8 expert will finish estimate price within 1 business day"
        Icon={Success}
      />
      <div className="flex flex-col px-5 w-full max-w-[1440px]">
        <div className="flex items-start justify-between w-full">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <div className="text-xl font-bold text-gray-900">
                {propertyProblemOverviewInfo.property.address}
              </div>
              <div className={`text-sm font-bold text-${statusData.color}`}>
                {statusData.label}{" "}
                <span className="text-sm font-normal text-gray-900">
                  {dateDiff} days,{" "}
                  {propertyProblemOverviewInfo.property.offer_due === "N/A" ||
                  !propertyProblemOverviewInfo.property.offer_due
                    ? "no offer due data"
                    : `offer due on ${new Date(
                        propertyProblemOverviewInfo.property.offer_due
                      ).toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}`}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1 items-start">
              <div className="text-xl font-bold">
              {estimatePriceMin.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} - {estimatePrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
              <div className="text-sm">
                {t("newPricingStrategyPageExpertEstimatePriceTitle")}{" "}
                {!estimatePrice
                  ? "(no data)"
                  : `(valid until ${moment
                      .unix(estimate_price_expired_time)
                      .toDate()
                      .toLocaleString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })})`}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Button
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("newPricingStrategyBookVideoConsulationTitle")}
              subContent={t("newPricingStrategyBookVideoConsulationSubTitle")}
              Icon={Video}
              onClick={async () => {
                window.open(
                  `https://calendly.com/home8-support/30min`,
                  "_blank"
                );
              }}
            />
            <Button
                type={ButtonType.PRIMARY}
                loading={false}
                disabled={(data && data.user_requested && moment().isBefore(moment.unix(estimate_price_expired_time)))}
                content={t("newPricingStrategyRequestEstimateTitle")}
                subContent={t("newPricingStrategyRequestEstimateSubTitle")}
                Icon={Payment}
                onClick={async () => {
                  createRequest(propertyId);
                  setIsModalOpen(true);
                }}
              />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 w-full p-5 pb-[72px] max-w-[1440px]">
        <Table
          data={!comparedHighestProperty ? [] : [comparedHighestProperty]}
          columns={columnsUpper}
          loading={false}
          allowPagination={false}
          tableHeight={82}
        />
        <Table
          data={comparedProperties}
          columns={columnsLower}
          loading={false}
          allowPagination={false}
          tableHeight={82}
        />
      </div>
      <div className="fixed right-5 bottom-5">
        <ContactExpertButton />
      </div>
    </div>
  );
};

export default PricingStrategyPage;
